import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Typography,
  Divider,
} from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { HttpHandler, AuthValid } from '../../shared/httpHandler';
import { CryptoHandler } from '../../shared/cryptoHandler';
import LoginBgImage from '../../images/login-bg.jpeg';
import { localStorageClear } from '../../shared/common';
import User from '../system/User.js';

const { Title } = Typography;

const userDataEmpty = {
  id: null,
  account: '',
  name: '',
  department: '',
  reason: '',
  role_id: null,
  role_opts: [],
};

const Login = () => {
  const httpHandler = new HttpHandler(false);
  const cryptoHandler = new CryptoHandler();

  useEffect(() => {
    // 每次一進登入畫面，留下常駐資料，然後清空 localStorage
    let alwaysExistObj = localStorage.getItem('alwaysExist');
    localStorageClear();
    if (alwaysExistObj) {
      form.setFieldValue('loginAccount', JSON.parse(alwaysExistObj).account);
      form.setFieldValue('remember', JSON.parse(alwaysExistObj).remember);
    }
  }, []);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  //按下登入按鈕會執行的邏輯
  const handleLogin = (values) => {
    setLoading(true);
    // 記住帳號的內容(登出時不會被清空)
    let alwaysExistObj = {};
    if (values.remember) {
      alwaysExistObj.account = values.loginAccount;
      alwaysExistObj.remember = values.remember;
    }
    localStorage.setItem('alwaysExist', JSON.stringify(alwaysExistObj));

    httpHandler
      .post('/auth/login', {
        account: cryptoHandler.encrypt(values.loginAccount),
        password: cryptoHandler.encrypt(values.loginPassword),
      })
      .then((response) => {
        if (response.success) {
          console.log(response);
          // JWT 處理
          AuthValid.storeAuth(response.data.token, response.data.re_token);
          navigate('/dashboard');
        } else {
          window.antdNotify('錯誤', response.message, 'fail');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${LoginBgImage})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          height: '100vh',
        }}>
        <Row justify="center" align="middle" style={{ height: '100vh' }}>
          <Col
            xs={22}
            sm={16}
            md={12}
            lg={10}
            xl={10}
            style={{
              background: 'rgba(0, 0, 0, 0.2)',
              padding: '24px',
              borderRadius: '20px',
              boxShadow: '0 0 15px 1px #000000',
            }}>
            <Title level={1} style={{ color: '#fff', textAlign: 'center' }}>
              <div>和泰智能客休管理平台</div>
            </Title>
            <Form form={form} onFinish={handleLogin}>
              <Form.Item
                name="loginAccount"
                rules={[
                  {
                    required: true,
                    message: '請輸入帳號',
                  },
                ]}>
                <Input
                  prefix={
                    <>
                      <UserOutlined />
                      <span>帳號&nbsp;&nbsp;</span>
                    </>
                  }
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="loginPassword"
                rules={[
                  {
                    required: true,
                    message: '請輸入密碼',
                  },
                ]}>
                <Input.Password
                  prefix={
                    <>
                      <LockOutlined />
                      <span>密碼&nbsp;&nbsp;</span>
                    </>
                  }
                  size="large"
                />
              </Form.Item>
              <Form.Item>
                <Row align="middle">
                  <Col span={24}>
                    <Form.Item name="remember" valuePropName="checked">
                      <Checkbox>
                        <span
                          style={{
                            color: '#fff',
                            fontSize: 'large',
                            fontWeight: '600',
                          }}>
                          記住我
                        </span>
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item>
                <Row align="middle">
                  <Col span={24}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      size="large"
                      block>
                      登入
                    </Button>
                  </Col>
                </Row>
              </Form.Item>
            </Form>
            <Divider />
            <Row justify="center" style={{ marginTop: 4, marginBottom: 60 }}>
              <Col span={2}></Col>
              {/* 不需要新增使用者功能 */}
              {/* <Col span={20} className="alignitems-center">
                <User modeType="addWithoutRole" formData={userDataEmpty} />
              </Col> */}
              <Col span={2}></Col>
            </Row>
          </Col>
        </Row>
      </div>
      <>
        {/* 現有的代碼 */}
        <div
          style={{
            position: 'fixed',
            right: '20px',
            bottom: '20px',
            color: '#fff',
          }}>
          Version {process.env.REACT_APP_VERSION}
        </div>
      </>
    </>
  );
};

export default Login;
