import React from 'react';
import { Routes, Route } from 'react-router-dom';
import QRCode from 'pages/QRCode/QRCode';

const QRCodeRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<QRCode />} />
    </Routes>
  );
};

export default QRCodeRouter;
