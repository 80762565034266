import { React, useState, useEffect } from 'react';
import PageHeader from 'utility/PageHeader';
import PageBody from 'utility/PageBody';
import { Button, Form, Input, Row, Col, Table, Modal, Image } from 'antd';
import {
  SearchOutlined,
  QrcodeOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { HttpHandler } from '../../shared/httpHandler';
const httpHandler = new HttpHandler();

const locationTypeMapping = { 1: '服務廠', 2: '保修站' };

const QRCode = () => {
  const [searchForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalImageData, setModalImageData] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
    updateData(pagination);
  };

  const updateData = (pagination = tableParams.pagination) => {
    let { name, code } = searchForm.getFieldsValue();
    name = name ? name.trim() : '';
    code = code ? code.trim() : '';
    const { current, pageSize } = pagination;
    setLoading(true);
    httpHandler
      .get(
        `/service_location/list_with_tables?name=${name}&location_code=${code}&page=${current}&page_size=${pageSize}&is_all=false`
      )
      .then((response) => {
        if (response.success) {
          setTableData(response.data);
          setTableParams({
            ...tableParams,
            pagination: {
              ...pagination,
              total: response.total,
            },
          });
        } else {
          window.antdNotify('錯誤', response.message, 'fail');
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const search = async () => {
    setTableParams({
      ...tableParams,
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
    updateData({
      current: 1,
      pageSize: 10,
    });
  };

  // 搜尋組件
  const SearchBar = () => {
    return (
      <>
        <Form form={searchForm} name="searchForm" onFinish={search}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="據點代碼" name="code">
                <Input allowClear={true} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="據點名稱" name="name">
                <Input allowClear={true} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
                className="button button--search">
                搜尋
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  // QRCode檢視
  const viewQRCode = async (
    title,
    locationType,
    locationCode,
    locationName,
    areaId = null,
    areaName = null,
    tableId = null,
    tableName = null
  ) => {
    setImageLoading(true);
    setModalImageData(null);
    setModalTitle(title);

    let url = `/qr_code/image?location_type=${locationType}&location_code=${locationCode}&location_name=${locationName}`;
    if (areaId && areaName && tableId && tableName) {
      url += `&area_id=${areaId}&area_name=${areaName}&table_id=${tableId}&table_name=${tableName}`;
    }

    return httpHandler
      .get(url)
      .then((response) => {
        if (response.success) {
          setModalImageData(response.data.image);
          setModalIsOpen(true);
        } else {
          window.antdNotify('錯誤', response.message, 'fail');
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setImageLoading(false);
      });
  };

  // 下載全 QRCode 的 zip 壓縮檔
  const downloadAllQRCode = (locationCode, locationName) => {
    setDownloadLoading(true);
    const fileName = `QRCodes_${locationCode}_${locationName}.zip`;
    window.antdNotify(
      '下載已開始',
      `正在努力下載 "${fileName}"，請勿離開此頁面。`,
      'info'
    );
    httpHandler
      .get(`/qr_code/download_all?location_code=${locationCode}`)
      .then((response) => {
        if (response.success) {
          // 從 response 中提取 base64 編碼的 zip 文件
          const zipBase64 = response.data.zip_file.split(',')[1];

          // 將 base64 轉換為 blob
          const blob = base64ToBlob(zipBase64, 'application/zip');

          // 建立下載連結並觸發下載
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          window.antdNotify(
            '成功',
            `"${fileName}" 下載成功，請確認您的下載資料夾。`,
            'success'
          );
        } else {
          window.antdNotify('錯誤', response.message, 'fail');
        }
      })
      .catch((error) => {
        console.error('下載 QR codes 時發生錯誤:', error);
        window.antdNotify('錯誤', '下載 QR codes 時發生錯誤', 'fail');
      })
      .finally(() => {
        setDownloadLoading(false);
      });
  };

  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  };

  // 表格組件
  const DataTable = () => {
    let columns = [
      {
        title: 'id',
        dataIndex: 'id',
        hidden: true,
      },
      {
        title: '操作',
        dataIndex: '',
        width: 120,
        render: (value, row) => {
          if (row.location_type === 1) {
            return (
              <Button
                icon={<DownloadOutlined />}
                type="primary"
                className="button--edit"
                loading={downloadLoading}
                onClick={(e) => {
                  e.stopPropagation();
                  downloadAllQRCode(row.location_code, row.name);
                }}>
                下載全 QR Code
              </Button>
            );
          } else {
            return (
              <Button
                icon={<QrcodeOutlined />}
                type="primary"
                className="button--edit"
                loading={imageLoading}
                onClick={() => {
                  viewQRCode(
                    `${row.location_code}-${row.name}`,
                    row.location_type,
                    row.location_code,
                    row.name
                  );
                }}>
                QR Code
              </Button>
            );
          }
        },
      },
      {
        title: '代碼',
        dataIndex: 'location_code',
        width: 120,
      },
      {
        title: '類別',
        dataIndex: 'location_type',
        width: 120,
        render: (value, row) => {
          return locationTypeMapping[value];
        },
      },
      {
        title: '名稱',
        dataIndex: 'name',
      },
    ].filter((item) => !item.hidden);

    const onExpandedRowsChange = (expandedRows) => {
      setExpandedRowKeys(expandedRows);
    };

    return (
      <Table
        columns={columns}
        rowKey={(row) => row.id}
        dataSource={tableData}
        pagination={tableParams.pagination}
        loading={loading}
        bordered="true"
        scroll={{ x: 'max-content' }}
        onChange={handleTableChange}
        expandable={{
          rowExpandable: (row) => row.location_type === 1,
          expandedRowRender: (row) => <NestedDataTable parentData={row} />,
          expandRowByClick: true,
          expandedRowKeys: expandedRowKeys,
          onExpandedRowsChange: onExpandedRowsChange,
        }}
      />
    );
  };

  // 子表格組件
  const NestedDataTable = ({ parentData }) => {
    const tableData = parentData.location_tables;
    let columns = [
      {
        title: 'id',
        dataIndex: 'TBID',
        hidden: true,
      },
      {
        title: '操作',
        dataIndex: '',
        width: 120,
        render: (value, row) => {
          return (
            <Button
              icon={<QrcodeOutlined />}
              type="primary"
              className="button--edit"
              loading={imageLoading}
              onClick={() => {
                viewQRCode(
                  `${parentData.location_code}-${parentData.name}-${row.RMNM}-${row.TBNM}`,
                  parentData.location_type,
                  parentData.location_code,
                  parentData.name,
                  row.RMID,
                  row.RMNM,
                  row.TBID,
                  row.TBNM
                );
              }}>
              QR Code
            </Button>
          );
        },
      },
      {
        title: '區域',
        dataIndex: 'RMNM',
      },
      {
        title: '座位名稱',
        dataIndex: 'TBNM',
      },
    ].filter((item) => !item.hidden);

    return (
      <Table
        columns={columns}
        rowKey={(row) => row.TBID}
        dataSource={tableData}
        bordered="true"
        pagination={false}
        showHeader={true}
        footer={() => <></>}
      />
    );
  };

  const QRCodeModal = ({ title, imageData, isOpen, setIsOpen }) => {
    const handleDownload = () => {
      const link = document.createElement('a');
      link.href = imageData;
      link.download = `${title}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    return (
      <Modal
        title={title}
        open={isOpen}
        footer={[
          <Button key="download" onClick={handleDownload}>
            <DownloadOutlined />
            下載
          </Button>,
        ]}
        onCancel={() => {
          setIsOpen(false);
        }}>
        <Image src={imageData} preview={false} />
      </Modal>
    );
  };

  const section = [
    {
      hasTitle: false,
      title: 'Search',
      children: <SearchBar />,
    },
    {
      hasTitle: false,
      title: 'Data',
      children: <DataTable />,
    },
    {
      hasTitle: false,
      title: 'QRCodeModal',
      children: (
        <QRCodeModal
          title={modalTitle}
          imageData={modalImageData}
          isOpen={modalIsOpen}
          setIsOpen={setModalIsOpen}
        />
      ),
    },
  ];

  useEffect(() => {
    // 預設表單值
    searchForm.setFieldsValue({
      code: '',
      name: '',
    });
    // 進入畫面時執行一次搜尋
    search();
  }, []);

  return (
    <div>
      <PageHeader functionName={'qr_code'}></PageHeader>
      <PageBody section={section}></PageBody>
    </div>
  );
};

export default QRCode;
