import { React, useState, useEffect } from 'react';
import { Button, Modal, Form, Input, Divider, Select } from 'antd';
import { UserAddOutlined, EditOutlined } from '@ant-design/icons';
import { HttpHandler } from '../../shared/httpHandler';
import PropTypes from 'prop-types';

const modeRef = {
  add: {
    text: '新增',
    icon: <UserAddOutlined />,
    class: 'register',
    api: '/user_maintenance/add_user',
  },
  edit: {
    text: '編輯',
    icon: <EditOutlined />,
    class: 'edit',
    api: '/user_maintenance/edit_user',
  },
  addWithoutRole: {
    text: '新用戶註冊',
    icon: <UserAddOutlined />,
    class: 'register',
    api: '/auth/add_user',
  },
};
const formDataEmpty = {
  id: null,
  account: '',
  name: '',
  department: '',
  reason: '',
  role_id: null,
};

const User = ({ modeType, formData, okFunc, okFuncProps }) => {
  const httpHandler = new HttpHandler();
  const [roleOpts, setRoleOpts] = useState([]);
  const mode = modeRef[modeType];
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    // console.log('Received values of form: ', values);
    const confirmed = await window.antdConfirm(`確定${mode.text}使用者嗎？`);
    if (confirmed) {
      setLoading(true);
      httpHandler
        .post(mode.api, values)
        .then((response) => {
          if (response.success) {
            window.antdNotify(
              `${mode.text}成功！`,
              response.message,
              'success'
            );
            setVisible(false);
            form.setFieldsValue(formDataEmpty);

            if (typeof okFunc === 'function') {
              okFunc(okFuncProps);
            }
          } else {
            window.antdNotify('錯誤', response.message, 'fail');
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    form.setFieldsValue(formData);
    setRoleOpts(formData.role_opts);
  }, [form, formData]);

  return (
    <>
      <Button
        icon={mode.icon}
        type="primary"
        className={`button button--${mode.class}`}
        onClick={() => setVisible(true)}
        block>
        {mode.text}
      </Button>
      <Modal
        forceRender
        title={
          <>
            {mode.icon}
            &nbsp; {mode.text}
            {modeType === 'addWithoutRole' ? '' : '使用者'}
          </>
        }
        open={visible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button
            key="back"
            loading={loading}
            onClick={() => setVisible(false)}>
            取消
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => form.submit()}>
            確定
          </Button>,
        ]}>
        <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Divider></Divider>
          <Form.Item label="id" name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item
            label="帳號"
            name="account"
            rules={[
              { required: true, message: '請輸入帳號!' },
              {
                pattern: /^[A-Za-z0-9]{6,12}$/,
                message: '帳號必須為6~12位英數字!',
              },
            ]}>
            <Input readOnly={modeType === 'edit'} />
          </Form.Item>
          <Form.Item
            label="姓名"
            name="name"
            rules={[{ required: true, message: '請輸入姓名!' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="部門" name="department">
            <Input />
          </Form.Item>
          <Form.Item label="原因" name="reason">
            <Input />
          </Form.Item>
          {modeType !== 'addWithoutRole' ? (
            <Form.Item
              label="角色"
              name="role_id"
              rules={[{ required: true, message: '請選擇角色！' }]}>
              <Select options={roleOpts} />
            </Form.Item>
          ) : (
            <></>
          )}
        </Form>
      </Modal>
    </>
  );
};

User.propTypes = {
  modeType: PropTypes.oneOf(Object.keys(modeRef)).isRequired,
  formData: PropTypes.object,
  okFunc: PropTypes.func,
  okFuncProps: PropTypes.object,
};

export default User;
