import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Space, Col, Row } from 'antd';
import { DoubleRightOutlined } from '@ant-design/icons';

const ProductItem = ({ src, alt, brand, extra, externalLink }) => {
  const handleClick = () => {
    if (externalLink) {
      window.location.href = externalLink;
    }
  };

  return (
    // Webview客製化Title
    (document.title = 'TOYOTA商品專區'),
    (
      <Col xs={12} sm={6} md={6}>
        <div
          className="item-container"
          onClick={handleClick}
          style={{ cursor: 'pointer' }}>
          <img src={src} alt={alt} style={{ width: '100%' }} />
          <div className="body1 text-ellipsis">{brand}</div>
          {extra && (
            <div className="body1 blue">
              {extra}
              <DoubleRightOutlined />
            </div>
          )}
        </div>
      </Col>
    )
  );
};

const FeaturedProduct = () => {
  return (
    <div className="menu-container">
      <Space direction="vertical" size="middle">
        <Space direction="vertical" size="small">
          <img
            src="/images/logo/toyota_logo_horizontal.svg"
            alt="Toyota Logo"
            style={{ width: '120px' }}
          />
          <div className="heading1">商品專區</div>
        </Space>
        <Row gutter={[16, 16]}>
          <ProductItem
            src="/images/picture/toyota_battery.png"
            alt="Battery"
            brand="TOYOTA嚴選電瓶"
            extra="去逛逛"
            externalLink="https://www.toyota.com.tw/Car_Battery/?utm_source=google&utm_medium=keyword&utm_campaign=battery&gad_source=1"
          />
          <ProductItem
            src="/images/picture/yokohama_tire.png"
            alt="Tire"
            brand="橫濱輪胎"
            extra="去逛逛"
            externalLink="https://www.toyota.com.tw/event/202104_toyota_toyotatire_v1/"
          />
          <ProductItem
            src="/images/picture/toyota_tus.png"
            alt="TOYOTA Upgrade Selections"
            brand="TUS 升級服務"
            extra="去逛逛"
            externalLink="https://www.toyota.com.tw/tus/"
          />
          <ProductItem
            src="/images/picture/collab_product.png"
            alt="Collab Product"
            brand="TOYOTA精品"
            extra="去逛逛"
            externalLink="/toyota_collection.pdf"
          />
        </Row>
      </Space>
    </div>
  );
};

export default FeaturedProduct;
