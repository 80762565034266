import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './main.scss';
import AppRouter from './router/AppRouter';
import ConfirmModal from './utility/ConfirmModal';
import AlertModal from './utility/AlertModal';
import AntdNotification from './utility/AntdNotification';

const App = () => {
  return (
    // browserRouter 是為了讓頁面可以用路徑切換
    <BrowserRouter>
      <AppRouter />
      <ConfirmModal />
      <AlertModal />
      <AntdNotification />
    </BrowserRouter>
  );
};

export default App;
