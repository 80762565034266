import React from 'react';
import { Result, Button } from 'antd';

const Error500 = () => (
  <Result
    status="500"
    title="500"
    subTitle="系統異常！請重新操作，或聯絡相關人員。"
    // extra={
    //   <Button type="primary" href="/home">
    //     返回首頁
    //   </Button>
    // }
  />
);

export default Error500;
