import React, { useState, useEffect } from 'react';
import {
  Space,
  Col,
  Row,
  Spin,
  Empty,
  Button,
  Divider,
  List,
  Avatar,
  Alert,
  Modal,
  Result,
} from 'antd';
import {
  PlusOutlined,
  MinusOutlined,
  FileDoneOutlined,
  InfoCircleOutlined,
  CheckOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { WebviewHttpHandler } from 'shared/webviewHttpHandler';

const httpHandler = new WebviewHttpHandler();

// 客休備品訂單單次最多數量
const loungeMaxQuantity = process.env.REACT_APP_LOUNGE_MAX_QUANTITY || 1;
// 客休備品訂單時間間隔(分鐘)
const loungeMiniInterval = process.env.REACT_APP_LOUNGE_MINI_INTERVAL || 5;

// 排序寫死
const amenityListSort = [
  '腰背按摩器',
  '腰靠墊',
  '置物籃',
  '手機架',
  '充電線',
  '行動電源',
  '兒童座椅',
];

const ServiceItem = ({ id, src, alt, name, onQuantityChange }) => {
  const [itemQty, setItemQty] = useState(0);
  const handleQuantityChange = (newQty) => {
    setItemQty(newQty);
    onQuantityChange(id, name, src, newQty);
  };

  return (
    <Col xs={12} sm={6} md={6}>
      {' '}
      {/* 響應式列跨度設置 */}
      <div className="item-container">
        {src ? (
          <img src={src} alt={alt} style={{ width: '100%' }} />
        ) : (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={'尚未提供圖片'}
          />
        )}
        <div className="body1 text-ellipsis">{name}</div>
        <div className="mt-2">
          <Space>
            <Button
              icon={<MinusOutlined />}
              onClick={() => handleQuantityChange(Math.max(0, itemQty - 1))}
            />
            <span className="body1" style={{ margin: '0 8px' }}>
              {itemQty}
            </span>
            <Button
              icon={<PlusOutlined />}
              onClick={() =>
                handleQuantityChange(Math.min(loungeMaxQuantity, itemQty + 1))
              }
            />
          </Space>
        </div>
      </div>
    </Col>
  );
};

const LoungeService = () => {
  const [userLocationCode, setUserLocationCode] = useState();
  const [searchStatus, setSearchStatus] = useState(1);
  const [menuList, setMenuList] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [orderTotalQuantity, setOrderTotalQuantity] = useState(0);
  const [orderLoading, setOrderLoading] = useState(false);
  const [orderAlert, setOrderAlert] = useState({
    show: false,
    message: '',
    description: '',
  });
  const [orderAlertPercent, setOrderAlertPercent] = useState(0);
  const [resetKey, setResetKey] = useState(0);
  const [resultModal, setResultModal] = useState({
    show: false,
    status: '',
    title: '',
    subTitle: '',
  });

  // 重組response的item排序
  const reformatItemSort = (sortList, resList) => {
    var newList = [];
    sortList.map((item) => {
      const index = resList.findIndex((coldDrink) => coldDrink.name === item);

      if (index !== -1) {
        const sortItem = resList.splice(index, 1)[0];
        newList.push(sortItem);
      }
    });
    newList.push(...resList);
    return newList;
  };

  const handleQuantityChange = (id, name, image, qty) => {
    setOrderItems((prevItems) => {
      const existingItemIndex = prevItems.findIndex(
        (item) => item.id === id
      );

      if (existingItemIndex !== -1) {
        // 餐點已經在清單中，更新數量就好
        const updatedItems = [...prevItems];
        if (qty === 0) {
          // 若新的數量為0，則從清單中移除
          updatedItems.splice(existingItemIndex, 1);
        } else {
          // 更新數量
          updatedItems[existingItemIndex] = {
            ...updatedItems[existingItemIndex],
            qty,
          };
        }
        return updatedItems;
      } else if (qty > 0) {
        // 餐點不存在且數量大於0，新增餐點
        return [...prevItems, { id, order_type: 2, name, image, qty }];
      }

      // 餐點不存在且數量為0，不改變清單
      return prevItems;
    });
  };

  const handleOrder = () => {
    setOrderLoading(true);

    // 檢查點餐間隔
    const lastOrderTime = localStorage.getItem('loungeLastOrderTime');
    const currentTime = new Date().getTime();
    const timeDiff = currentTime - lastOrderTime;
    const minutesDiff = Math.floor(timeDiff / (1000 * 60));

    if (minutesDiff < loungeMiniInterval) {
      setOrderLoading(false);
      setOrderAlert({
        show: true,
        progressShow: true,
        message: '點餐間隔不足',
        description: `點餐間隔需大於 ${loungeMiniInterval} 分鐘`,
      });

      setOrderAlertPercent(100);

      const interval = setInterval(() => {
        setOrderAlertPercent((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            setOrderAlert((prevAlert) => ({ ...prevAlert, show: false }));
            return 0;
          }
          return prev - 1;
        });
      }, 30); // 3000ms / 100 = 30ms per 1%

      return;
    }

    // 送出訂單
    const items = orderItems.map(({ image, ...item }) => item);
    httpHandler
      .post('/webview/custom_lounge/order', {
        user_id: localStorage.getItem('lineUserId'),
        items: items,
      })
      .then((res) => {
        if (res.success) {
          localStorage.setItem('loungeLastOrderTime', new Date().getTime());
          setResultModal({
            show: true,
            status: 'success',
            title: '送出訂單成功',
            subTitle: res.message,
          });
          resetOrder();
        } else {
          setResultModal({
            show: true,
            status: 'error',
            title: '送出訂單失敗',
            subTitle: res.message,
          });
        }
      })
      .catch((err) => {
        console.log('err', err);
        setResultModal({
          show: true,
          status: 'error',
          title: '送出訂單失敗',
          subTitle: '系統錯誤',
        });
      })
      .finally(() => {
        setOrderLoading(false);
      });
  };

  const resetOrder = () => {
    // 透過更新 key 的方式，強制重新渲染 <MenuItem />，使每個項目的數量歸零
    setResetKey((prevKey) => prevKey + 1);
    setOrderItems([]);
    setOrderTotalQuantity(0);
    setOrderAlert({
      show: false,
      progressShow: false,
      message: '',
      description: '',
    });
  };

  useEffect(() => {
    httpHandler
      .get(
        `user_location/get_user_location?user_id=${localStorage.getItem(
          'lineUserId'
        )}`
      )
      .then((res) => {
        if (res.success) {
          setUserLocationCode(res.data.site_id);
        } else {
          window.antdNotify('錯誤', res.message, 'fail');
        }
      });
  }, []);

  useEffect(() => {
    if (userLocationCode) {
      httpHandler
        .get(`/webview/custom_lounge/list?location_code=${userLocationCode}`)
        .then((res) => {
          if (res.success) {
            // 客休服務菜單
            const amenityList = res.data.filter(
              (item) => item.item_type == '2'
            );
            setMenuList(reformatItemSort(amenityListSort, amenityList));
            setSearchStatus(0);
          } else {
            window.antdNotify('錯誤', res.message, 'fail');
          }
        });
    }
  }, [userLocationCode]);

  useEffect(() => {
    // 檢查訂單總數量是否超過限制
    const totalQuantity = orderItems.reduce(
      (total, item) => total + item.qty,
      0
    );
    setOrderTotalQuantity(totalQuantity);
    // setOrderAlert({
    //   show: totalQuantity > loungeMaxQuantity,
    //   progressShow: false,
    //   message: '超過數量限制',
    //   description: `單次訂單總量最多 ${loungeMaxQuantity} 份`,
    // });
  }, [orderItems]);

  return (
    // Webview客製化Title
    (document.title = 'TOYOTA客休服務'),
    (
      <div className="menu-container">
        <Space direction="vertical" size="middle">
          <Space direction="vertical" size="small">
            <img
              src="/images/logo/toyota_logo_horizontal.svg"
              alt="Toyota Logo"
              style={{ width: '120px' }}
            />
            <div className="heading1">客休服務</div>
            <div className="body1 grey">
              實際品項及供應數量以服務廠提供為主
              <br />
              歡迎向客服專員取用
            </div>
          </Space>
          {searchStatus === 1 ? (
            <Spin size="large" />
          ) : menuList.length === 0 ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="目前無提供客休服務"
            />
          ) : (
            <>
              <Row gutter={[16, 16]}>
                {menuList.map((item) => (
                  <ServiceItem
                    key={`${item.id}-${resetKey}`}
                    id={item.id}
                    src={item.image_base64}
                    alt={item.name}
                    name={item.name}
                    onQuantityChange={handleQuantityChange}
                  />
                ))}
              </Row>
              {/* 訂單確認及送出按鈕 */}
              <Divider>
                <div className="title1">
                  <FileDoneOutlined /> 訂單確認
                </div>
              </Divider>
              <div
                style={{
                  textAlign: 'left',
                  fontSize: '16px',
                  margin: '-20px 0 -20px 5px',
                  color: '#999',
                }}>
                <p>
                  <InfoCircleOutlined
                    style={{ marginRight: '8px', color: '#1677FF' }}
                  />
                  為確保服務品質，備品需求間隔為
                  <span style={{ color: '#1677FF' }}>
                    {' '}
                    {loungeMiniInterval}{' '}
                  </span>
                  分鐘，每項最多
                  <span style={{ color: '#1677FF' }}>
                    {' '}
                    {loungeMaxQuantity}{' '}
                  </span>
                  個。
                </p>
              </div>
              <div
                className="item-container"
                style={{ padding: '40px', minHeight: '246px' }}>
                <List
                  itemLayout="horizontal"
                  dataSource={orderItems}
                  locale={{
                    emptyText: (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="請點選上方菜單進行點單"
                      />
                    ),
                  }}
                  renderItem={(item, index) => (
                    <List.Item>
                      <Space direction="horizontal">
                        {/* <Avatar src={item.image} /> */}
                        <div className="body1">
                          {item.name} * {item.qty}
                        </div>
                      </Space>
                    </List.Item>
                  )}
                />
                {orderTotalQuantity > 0 && (
                  <>
                    <Divider dashed style={{ borderColor: '#999' }} />
                    <Space direction="horizontal">
                      <Button
                        icon={<DeleteOutlined />}
                        size="large"
                        style={{
                          color: '#999',
                        }}
                        onClick={resetOrder}
                        loading={orderLoading}>
                        清除
                      </Button>
                      <Button
                        icon={<CheckOutlined />}
                        size="large"
                        style={{ width: '140px' }}
                        disabled={orderAlert.show}
                        onClick={handleOrder}
                        loading={orderLoading}
                        danger>
                        送出訂單
                      </Button>
                    </Space>
                  </>
                )}
                {orderAlert.show && (
                  <Alert
                    message={
                      <>
                        {orderAlert.message}
                        <div
                          style={{ position: 'absolute', top: 18, right: 20 }}
                          hidden={!orderAlert.progressShow}>
                          <Spin
                            percent={orderAlertPercent}
                            size="small"
                            style={{ textColor: 'red' }}
                          />
                        </div>
                      </>
                    }
                    description={orderAlert.description}
                    type="error"
                    style={{ marginTop: '16px', textAlign: 'left' }}
                    showIcon
                  />
                )}
              </div>
            </>
          )}
        </Space>
        <Modal
          open={resultModal.show}
          onCancel={() => setResultModal({ ...resultModal, show: false })}
          footer={[
            <Button
              onClick={() => setResultModal({ ...resultModal, show: false })}>
              關閉
            </Button>,
          ]}>
          <Result
            status={resultModal.status}
            title={resultModal.title}
            subTitle={resultModal.subTitle}
          />
        </Modal>
      </div>
    )
  );
};

export default LoungeService;
