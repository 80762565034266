import React from 'react';
import PropTypes from 'prop-types';
import StandardSection from 'utility/StandardSection';

const PageBody = ({ section }) => {
  return (
    <div className="main-container">
      {section.map((section, index) => (
        <StandardSection key={index} {...section} />
      ))}
    </div>
  );
};
PageBody.propTypes = {
  section: PropTypes.array,
};
export default PageBody;
