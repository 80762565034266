import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout, Space, Divider } from 'antd';
import MobileRouter from './../../router/MobileRouter';
import liff from '@line/liff';
import { WebviewAuthValid } from 'shared/webviewHttpHandler';

const { Header, Footer, Content } = Layout;

const MobileHome = () => {
  const navigate = useNavigate();
  const [lineUserId, setLineUserId] = useState();

  // 登入並取得 line user id
  const getLineUserId = () => {
    return new Promise((resolve, reject) => {
      console.log('redirectUri:', window.location.href);
      console.log(
        'process.env.REACT_APP_LIFF_ENTRY_ID:',
        process.env.REACT_APP_LIFF_ENTRY_ID
      );
      liff
        .init({ liffId: process.env.REACT_APP_LIFF_ENTRY_ID })
        .then(() => {
          // 如果用戶尚未登入，引導用戶登入
          if (!liff.isLoggedIn()) {
            liff.login({ redirectUri: window.location.href });
          }
          // 取得用戶資訊並設定line user id
          liff
            .getProfile()
            .then((profile) => {
              // 存進localStorage
              setLineUserId(profile.userId);
              localStorage.setItem('displayName', profile.displayName);
              localStorage.setItem('pictureUrl', profile.pictureUrl);
              localStorage.setItem('lineUserId', profile.userId);
              resolve(profile);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  };

  const handleDrinkClick = () => {
    navigate('/m/cx/DrinkMenu');
  };
  const handleLoungeClick = () => {
    navigate('/m/cx/LoungeService');
  };

  const handleProductClick = () => {
    navigate('/m/cx/FeaturedProduct');
  };

  const handlePointsClick = () => {
    navigate('/m/cx/HotaiPoints');
  };

  const handleProgressClick = () => {
    navigate('/m/cx/MaintenanceProgress');
  };

  useEffect(() => {
    WebviewAuthValid.clearAuth();
    getLineUserId();
  }, []);
  if (!lineUserId) {
    return <div>載入中...</div>;
  }
  return (
    <Layout className="mobile-home">
      {/* <Header className="home-header">
        <img
          src="/images/logo/toyota_logo_horizontal.svg"
          alt="Description"
          style={{ width: '120px' }} />
      </Header> */}
      <Content className="home-content">
        <MobileRouter />
        {/* <Footer className="home-footer">
          <Space
            size="middle"
            className="body1 grey"
            split={<Divider type="vertical" />}>
            <div onClick={handleDrinkClick}>飲品Menu</div>
            <div onClick={handleLoungeClick}>客休服務</div>
            <div onClick={handleProductClick}>商品專區</div>
            <div onClick={handlePointsClick}>和泰 Points</div>
            <div onClick={handleProgressClick}>保修進度</div>
          </Space>
        </Footer> */}
      </Content>
    </Layout>
  );
};

export default MobileHome;
