import { React, useEffect, useState } from 'react';
import PageHeader from 'utility/PageHeader';
import PageBody from 'utility/PageBody';
import { Button, Form, Input, Row, Col, Select, Table, Tag, Modal } from 'antd';
import { SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { HttpHandler } from '../../shared/httpHandler';
import PropTypes from 'prop-types';
import Role from './Role.js';
import { FunctionListHandler } from '../../shared/functionListHandler';

const roleDataEmpty = {
  id: null,
  name: '',
  description: '',
  function_list: '',
  function_list_opts: [],
};

const RoleMaintenance = () => {
  const httpHandler = new HttpHandler();
  const { Option } = Select;

  const [roleData, setRoleData] = useState(roleDataEmpty);
  const [searchForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
    updateData(pagination);
  };
  const updateData = (pagination = tableParams.pagination) => {
    const { name, is_cancel } = searchForm.getFieldsValue();
    const { current, pageSize } = pagination;
    setLoading(true);
    httpHandler
      .get(
        `/role_maintenance/get_role_list?name=${name}&is_cancel=${is_cancel}&page=${current}&page_size=${pageSize}`
      )
      .then((response) => {
        if (response.success) {
          setTableData(response.data);
          setTableParams({
            ...tableParams,
            pagination: {
              ...pagination,
              total: response.total,
            },
          });
        } else {
          window.antdNotify('錯誤', response.data.message, 'fail');
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const functionListHandler = new FunctionListHandler();
  const getFunctionList = () => {
    functionListHandler.getFunctionList().then((result) => {
      setRoleData({ ...roleData, function_list_opts: result });
    });
  };

  const SearchBar = () => {
    const onFinish = async (values) => {
      setTableParams({
        ...tableParams,
        pagination: {
          current: 1,
          pageSize: 10,
        },
      });
      updateData({
        current: 1,
        pageSize: 10,
      });
    };
    return (
      <>
        <Form form={searchForm} name="searchForm" onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="角色名稱" name="name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="狀態" name="is_cancel">
                <Select>
                  <Option value="-1">全部</Option>
                  <Option value="0">啟用中</Option>
                  <Option value="1">失效</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Role
                modeType="add"
                formData={roleData}
                okFunc={updateData}
                okFuncProps={tableParams.pagination}
              />
            </Col>
            <Col span={16} className="button-group">
              <Button
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
                className="button button--search">
                搜尋
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  const DataTable = (props) => {
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [role, setRole] = useState({});
    const confirmTitle = (
      <>
        <ExclamationCircleOutlined /> 變更審核狀態
      </>
    );
    const confirmContent = (
      <>
        確定將 <b>{role.name}</b> 的啟用狀態變更為 -&gt;{' '}
        {role.is_cancel === 1 ? (
          <Tag color="blue">啟用中</Tag>
        ) : (
          <Tag color="red">失效</Tag>
        )}{' '}
        嗎？
      </>
    );
    const confirmOk = async () => {
      setConfirmLoading(true);
      httpHandler
        .post('/role_maintenance/change_cancel', {
          role_id: role.id,
          new_cancel: role.is_cancel === 0 ? 1 : 0,
        })
        .then((response) => {
          if (response.success) {
            window.antdNotify('修改成功！', '', 'success');
            setConfirmOpen(false);
            updateData();
          } else {
            window.antdNotify('錯誤', response.message, 'fail');
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setConfirmLoading(false);
        });
    };
    const confirmCancel = () => {
      setConfirmOpen(false);
    };
    const changeCancel = (role) => {
      setRole(role);
      setConfirmOpen(true);
    };
    let columns = [
      {
        title: 'id',
        dataIndex: 'id',
        hidden: true,
      },
      {
        title: '編輯',
        render: (_, row) => {
          return (
            <Role
              modeType="edit"
              formData={{
                ...row,
                function_list_opts: roleData.function_list_opts,
              }}
              okFunc={updateData}
              okFuncProps={tableParams.pagination}
            />
          );
        },
      },
      {
        title: '啟用狀態',
        dataIndex: 'is_cancel',
        render: (_, row) => {
          if (row.is_cancel === 1) {
            return (
              <Button danger onClick={() => changeCancel(row)}>
                失效
              </Button>
            );
          } else {
            return (
              <Button type="primary" onClick={() => changeCancel(row)}>
                啟用中
              </Button>
            );
          }
        },
      },
      {
        title: '名稱',
        dataIndex: 'name',
      },
      {
        title: '描述',
        dataIndex: 'description',
      },
      {
        title: 'function_list',
        dataIndex: 'function_list',
        hidden: true,
      },
    ].filter((item) => !item.hidden);

    return (
      <>
        <Table
          columns={columns}
          rowKey={(row) => row.id}
          dataSource={props.tableData}
          pagination={tableParams.pagination}
          loading={loading}
          bordered="true"
          scroll={{ x: 'max-content' }}
          onChange={handleTableChange}
        />
        <Modal
          title={confirmTitle}
          open={confirmOpen}
          onOk={confirmOk}
          confirmLoading={confirmLoading}
          onCancel={confirmCancel}>
          {confirmContent}
        </Modal>
      </>
    );
  };

  DataTable.propTypes = {
    tableData: PropTypes.array,
  };

  useEffect(() => {
    getFunctionList();
    searchForm.setFieldsValue({
      name: '',
      is_cancel: '-1',
    });
    updateData();
  }, [searchForm]);

  const section = [
    {
      hasTitle: false,
      title: 'Search',
      children: <SearchBar />,
    },
    {
      hasTitle: false,
      title: 'Data',
      children: <DataTable tableData={tableData} />,
    },
  ];

  return (
    <div>
      <PageHeader functionName={'role_maintenance'}></PageHeader>
      <PageBody section={section}></PageBody>
    </div>
  );
};

export default RoleMaintenance;
