import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/basic/Home';

import Login from '../pages/basic/Login';
import Error401 from '../pages/errorPages/Error401';
import Error403 from '../pages/errorPages/Error403';
import Error404 from '../pages/errorPages/Error404';
import Error500 from '../pages/errorPages/Error500';
import MobileHome from '../pages/mobile/MobileHome';

import SystemRouter from './home/SystemRouter';
import ChangePasswordRouter from './home/ChangePasswordRouter';
import CustomLoungeRouter from './home/CustomLoungeRouter';
import ServiceLocationRouter from './home/ServiceLocationRouter';
import DealerRouter from './home/DealerRouter';
import QRCodeRouter from './home/QRCodeRouter';
import MainEX from '../pages/homeDefault/MainEX';

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/dashboard/Login" element={<Login />}></Route>
      <Route path="/dashboard/*" element={<Home />}>
        <Route path="System/*" element={<SystemRouter />}></Route>
        <Route
          path="ChangePassword/*"
          element={<ChangePasswordRouter />}></Route>
        <Route path="CustomLounge/*" element={<CustomLoungeRouter />}></Route>
        <Route
          path="ServiceLocation/*"
          element={<ServiceLocationRouter />}></Route>
        <Route path="Dealer/*" element={<DealerRouter />}></Route>
        <Route path="QRCode/*" element={<QRCodeRouter />}></Route>
        <Route path="*" element={<MainEX />}></Route>
      </Route>
      <Route path="m/*" element={<MobileHome />}></Route>
      <Route path="error404" element={<Error404 />}></Route>
      <Route path="error403" element={<Error403 />}></Route>
      <Route path="error401" element={<Error401 />}></Route>
      <Route path="error500" element={<Error500 />}></Route>
      <Route path="*" element={<Error404 />}></Route>
    </Routes>
  );
};

export default AppRouter;
