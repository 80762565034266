import { React, useState, useEffect } from 'react';
import PageHeader from 'utility/PageHeader';
import PageBody from 'utility/PageBody';
import { Button, Form, Input, Row, Col, Table, Modal, Typography } from 'antd';
import {
  SearchOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { HttpHandler } from '../../shared/httpHandler';
import PropTypes from 'prop-types';
const { Paragraph } = Typography;
const httpHandler = new HttpHandler();

// 父組件
const Dealer = () => {
  const [searchForm] = Form.useForm();
  const [modalForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
    updateData(pagination);
  };

  const updateData = (pagination = tableParams.pagination) => {
    let { name } = searchForm.getFieldsValue();
    if (name) {
      name = name.trim();
    }
    const { current, pageSize } = pagination;
    setLoading(true);
    httpHandler
      .get(
        `/dealer/list?name=${name}&page=${current}&page_size=${pageSize}&is_all=false`
      )
      .then((response) => {
        if (response.success) {
          setTableData(response.data);
          setTableParams({
            ...tableParams,
            pagination: {
              ...pagination,
              total: response.total,
            },
          });
        } else {
          window.antdNotify('錯誤', response.message, 'fail');
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      maskClosable: true, // 點擊蒙層是否允許關閉
      title: '確定刪除？',
      icon: <QuestionCircleOutlined />,
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn loading={deleteLoading} />
        </>
      ),
      okText: '確定',
      cancelText: '取消',
      onOk: async () => {
        setDeleteLoading(true);
        return httpHandler
          .delete(`/dealer/${id}`)
          .then((response) => {
            if (response.success) {
              window.antdNotify('成功', '刪除成功', 'success');
              search();
            } else {
              window.antdNotify('錯誤', response.message, 'fail');
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setDeleteLoading(false);
          });
      },
    });
  };

  const search = async () => {
    setTableParams({
      ...tableParams,
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
    updateData({
      current: 1,
      pageSize: 10,
    });
  };

  // 搜尋組件
  const SearchBar = () => {
    return (
      <>
        <Form form={searchForm} name="searchForm" onFinish={search}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="經銷商" name="name">
                <Input allowClear={true} />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
                className="button button--search">
                搜尋
              </Button>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setIsNew(true);
                  setIsModalVisible(true);
                }}
                className="button button--register">
                新增
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  };

  // 表格組件
  const DataTable = () => {
    const [isReadMoreModalVisible, setIsReadMoreModalVisible] = useState(false);
    const [currentText, setCurrentText] = useState('');
    const readMore = (text) => {
      const maxLength = 10; // 最大顯示的字元數
      if (text.length > maxLength) {
        return (
          <span>
            {text.substring(0, maxLength)}...
            <a
              onClick={() => {
                setCurrentText(text);
                setIsReadMoreModalVisible(true);
              }}>
              閱讀更多
            </a>
          </span>
        );
      }
      return text;
    };
    let columns = [
      {
        title: 'id',
        dataIndex: 'id',
        hidden: true,
      },
      {
        title: '操作',
        dataIndex: '',
        width: 240,
        render: (text, record, index) => {
          return (
            <>
              <Button
                icon={<EditOutlined />}
                type="primary"
                className="button--edit"
                style={{ marginRight: 16 }}
                onClick={() => {
                  setIsModalVisible(true);
                  setIsNew(false);
                  modalForm.setFieldsValue({
                    id: record.id,
                    name: record.name,
                    itemType: `${record.item_type}`,
                    memo: record.memo,
                  });
                }}>
                修改
              </Button>
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                className="button--delete"
                onClick={() => {
                  handleDelete(record.id);
                }}>
                刪除
              </Button>
            </>
          );
        },
      },
      {
        title: '名稱',
        dataIndex: 'name',
      },
      {
        title: '備註',
        dataIndex: 'memo',
        render: (text) => {
          if (text) {
            return readMore(text);
          }
        },
      },
    ].filter((item) => !item.hidden);

    return (
      <>
        <Table
          columns={columns}
          rowKey={(row) => row.id}
          dataSource={tableData}
          pagination={tableParams.pagination}
          loading={loading}
          bordered="true"
          scroll={{ x: 'max-content' }}
          onChange={handleTableChange}
        />
        <Modal
          title="完整內容"
          open={isReadMoreModalVisible}
          onOk={() => setIsReadMoreModalVisible(false)}
          onCancel={() => setIsReadMoreModalVisible(false)}>
          <Typography>
            <Paragraph>
              <pre>{currentText}</pre>
            </Paragraph>
          </Typography>
        </Modal>
      </>
    );
  };

  const section = [
    {
      hasTitle: false,
      title: 'Search',
      children: <SearchBar />,
    },
    {
      hasTitle: false,
      title: 'Data',
      children: <DataTable />,
    },
    {
      hasTitle: false,
      title: 'Modal',
      children: (
        <DataModal
          search={search}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          modalForm={modalForm}
          isNew={isNew}
        />
      ),
    },
  ];

  useEffect(() => {
    // 預設表單值
    searchForm.setFieldsValue({
      name: '',
    });
    // 進入畫面時執行一次搜尋
    search();
  }, []);

  return (
    <div>
      <PageHeader functionName={'dealer'}></PageHeader>
      <PageBody section={section}></PageBody>
    </div>
  );
};

// 共用的Modal
const DataModal = (props) => {
  const { search, isModalVisible, setIsModalVisible, modalForm, isNew } = props;
  const [loading, setLoading] = useState(false);

  const handleModalSubmit = async () => {
    try {
      const values = await modalForm.validateFields();
      const confirmed = await window.antdConfirm(
        `確定${isNew ? '新增' : '修改'}經銷商嗎？`
      );
      if (confirmed) {
        setLoading(true);
        // 根據isNew判斷是新增還是修改
        let requestPromise;
        if (isNew === true) {
          requestPromise = httpHandler.post('/dealer/', {
            name: values.name,
            memo: values.memo,
          });
        } else {
          requestPromise = httpHandler.put(`/dealer/${values.id}`, {
            name: values.name,
            memo: values.memo,
          });
        }
        requestPromise
          .then((response) => {
            if (response.success) {
              window.antdNotify('成功', response.message, 'success');
              setIsModalVisible(false);
              search();
              modalForm.resetFields();
            } else {
              window.antdNotify('錯誤', response.message, 'fail');
            }
          })
          .finally(() => setLoading(false));
      }
    } catch (error) {
      console.error('error: ', error);
    }
  };

  const handleModalCancel = () => {
    modalForm.resetFields();
    setIsModalVisible(false);
  };

  return (
    <Modal
      title={isNew ? '新增經銷商' : '修改經銷商'}
      open={isModalVisible}
      onCancel={handleModalCancel}
      footer={[
        <Button key="back" onClick={handleModalCancel} loading={loading}>
          取消
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleModalSubmit}
          loading={loading}>
          確定
        </Button>,
      ]}>
      <ModalForm modalForm={modalForm} />
    </Modal>
  );
};

// 共用Modal專用的Form(新增、修改共用)
const ModalForm = (props) => {
  const { modalForm } = props;
  return (
    <Form form={modalForm}>
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item
        label="名稱"
        name="name"
        rules={[{ required: true, message: '請輸入名稱!' }]}>
        <Input />
      </Form.Item>
      <Form.Item label="備註" name="memo">
        <Input.TextArea />
      </Form.Item>
    </Form>
  );
};

// 驗證props
DataModal.propTypes = {
  search: PropTypes.func,
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  modalForm: PropTypes.object,
};

ModalForm.propTypes = {
  modalForm: PropTypes.object,
};

export default Dealer;
