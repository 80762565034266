import React, { useState } from 'react';
import { Tooltip, Modal } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

function InfoTooltip({ info, modalContent }) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Tooltip title={info}>
        <InfoCircleOutlined onClick={showModal} />
      </Tooltip>

      <Modal
        title="Information"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}>
        {modalContent}
      </Modal>
    </>
  );
}

InfoTooltip.propTypes = {
  info: PropTypes.node.isRequired,
  modalContent: PropTypes.node.isRequired,
};

export default InfoTooltip;
