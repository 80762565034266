import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Space, Col, Row, Divider, Spin } from 'antd';
import { WebviewHttpHandler } from 'shared/webviewHttpHandler';

const httpHandler = new WebviewHttpHandler();
const HotaiPoints = () => {
  const [hotaiPoints, setHotaiPoints] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    httpHandler
      .get(
        `/webview/hotai_points/get_point?line_id=${localStorage.getItem(
          'lineUserId'
        )}`
      )
      .then((res) => {
        if (res.success) {
          setHotaiPoints(res.data.ResultData.CUSTDATA[0].POINTS);
        } else {
          window.antdNotify('', res.data.errorMessages, '');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <Space direction="vertical" size="middle">
      <Space direction="vertical" size="small">
        <img
          src="/images/logo/toyota_logo_horizontal.svg"
          alt="Toyota Logo"
          style={{ width: '120px' }}
        />
        <div className="heading1">和泰 Points</div>
      </Space>

      <div className="info-container">
        <Spin spinning={loading}>
          <Row>
            <Space direction="vertical" size="middle">
              <Space size="small">
                <img
                  src="/images/illustration/point.svg"
                  alt="Car Illustration"
                  style={{ width: '36px' }}
                />
                <div className="heading1 red">{hotaiPoints}</div>
              </Space>
              <div className="body1">目前點數：{hotaiPoints} 點</div>
            </Space>
          </Row>
        </Spin>
      </div>
    </Space>
  );
};

export default HotaiPoints;
