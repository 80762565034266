import React from 'react';
import { Card, Space } from 'antd';
import PropTypes from 'prop-types';
import InfoTooltip from './InfoTooltip';

const StandardSection = (props) => {
  return (
    <Card
      className="standard-section-card"
      bordered={false}
      title={
        props.hasTitle ? (
          <Space>
            {props.title}
            {props.hasInfo ? (
              <InfoTooltip
                info={props.info}
                modalContent={props.modalContent}></InfoTooltip>
            ) : (
              ''
            )}
          </Space>
        ) : (
          false
        )
      }>
      {props.children}
    </Card>
  );
};

StandardSection.propTypes = {
  children: PropTypes.node.isRequired,
  hasTitle: PropTypes.bool,
  title: PropTypes.node.isRequired,
  hasInfo: PropTypes.bool,
  info: PropTypes.node,
  modalContent: PropTypes.node,
};

export default StandardSection;
