import { FormattedMessage } from 'react-intl';
import React from 'react';
import { Form, Input, Col } from 'antd';

// 詳細使用說明參閱文件(doc/common使用說明.md)

const gridSettingGenerator = (index) => {
  const xs = 24;
  const sm = 24;
  let md = { span: 11, push: (index - 1) % 2 };
  let lg = { span: 7, push: (index - 1) % 3 };
  if (index === 0) {
    lg = 24;
    md = 24;
  }
  return { xs, sm, md, lg };
};

const gridSettingGeneratorForModal = (index) => {
  const xs = 24;
  const sm = 24;
  const md = 24;
  let lg = { span: 11, push: (index - 1) % 2 };
  if (index === 0) {
    lg = 24;
  }
  return { xs, sm, md, lg };
};

const strToColumnPx = (str) => {
  if (typeof str !== 'string') {
    str = String(str);
  }
  // 匹配中文字符的正則表達式
  var cnReg = /[\u4e00-\u9fa5]/g;
  // 匹配大寫英文字母的正則表達式
  var upperReg = /[A-Z]/g;
  // 匹配小寫英文字母的正則表達式
  var lowerReg = /[a-z]/g;
  // 匹配數字的正則表達式
  var numReg = /[0-9]/g;
  // 使用正則表達式的match()方法来查找匹配的字符，並返回匹配數组的長度
  var cnCount = (str.match(cnReg) || []).length;
  var upperCount = (str.match(upperReg) || []).length;
  var lowerCount = (str.match(lowerReg) || []).length;
  var numCount = (str.match(numReg) || []).length;
  // 返回包含字符數量的物件
  return (
    49 + cnCount * 14 + upperCount * 11 + lowerCount * 9 + numCount * 9 + 'px'
  );
};
// intlTranslate Formatted標籤版本  注意：不能和strToColumnPx共存 如果頁面有要使用 請另外做useIntl版本的 不可以使用這個
const intlTranslate = (id) => {
  return <FormattedMessage id={id} />;
};

// const title_width_generate_tr = (name, tr) => {
//   return {
//     title: intlTranslate(tr + name),
//     width: strToColumnPx(intlTranslate(tr + name)),
//   };
// };
// 棄用 判斷長度上會有問題

// const data_list_example = [
//   { initialValue: 'aaa', label: 'a label', itemName: 'a name' },
// ];

const makeDataForDataList = (data_origin, intlTranslateId, dataInRow) => {
  return data_origin.map((itemName) => ({
    label: intlTranslate(intlTranslateId + itemName),
    initialValue: dataInRow[itemName],
    itemName: itemName,
  }));
};

const modal_input_generate = (data_list, disabled = true) => {
  const inputs = data_list.map((item, index) => (
    <Col
      xs={24}
      sm={{ span: 11, offset: index % 2 == 0 ? 0 : 1 }}
      key={Math.random()}
    >
      <Form.Item
        initialValue={item.initialValue ? item.initialValue : ''}
        name={item.itemName}
        label={item.label}
      >
        <Input disabled={disabled} />
      </Form.Item>
    </Col>
  ));
  return inputs;
};

/**
 * 根據給定的大小參數返回相應的對話框大小設置
 * @param {string} size - 對話框大小，可選 'large', 'normal', 'small'
 * @enum {string} - large：適用modal中有很多的內容 甚至會有兩欄的版型使用
 * - normal：適用modal中只有不太多的內容 會建議用一欄的版型
 * - small：適用這個modal只是一個警示窗 讓使用者在確認一次要執行這個動作使用
 * @returns {Object} - 對話框大小設置對象，包含寬度屬性
 * @example <Modal {...modal_size('large')} >
 */
const modal_size = (size) => {
  if (size == 'large') {
    return { width: 800 };
  }
  if (size == 'normal') {
    return { width: 520 };
  }
  if (size == 'small') {
    return { width: 400 };
  }
};

const localStorageClear = () => {
  let alwaysExistObj = localStorage.getItem('alwaysExist');
  let language = localStorage.getItem('language');
  localStorage.clear();
  if (alwaysExistObj) {
    localStorage.setItem('alwaysExist', alwaysExistObj);
  }
  localStorage.setItem('language', language);
};

export {
  gridSettingGenerator,
  strToColumnPx,
  intlTranslate,
  makeDataForDataList,
  modal_input_generate,
  gridSettingGeneratorForModal,
  modal_size,
  localStorageClear,
};
