import React from 'react';
import {
  Space,
  Table,
  Tag,
  Tabs,
  Divider,
  Button,
  Form,
  Input,
  Row,
  Col,
} from 'antd';

const LoginBgImage = require('../../images/login-bg.jpeg');
export default function MainEX() {
  return (
    <>
      <div className="cover">
        <img
          src={LoginBgImage}
          alt="BigCo Inc. logo"
          width={'100%'}
          height={'100%'}
        />
      </div>
    </>
  );
}
