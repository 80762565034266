import { HttpHandler } from './httpHandler';

const httpHandler = new HttpHandler();

class FunctionListHandler {
  async getFunctionList() {
    return httpHandler
      .get('/function_list/get_function_list')
      .then((response) => {
        if (response.success) {
          return response.data;
        } else {
          window.antdNotify('錯誤', response.message, 'fail');
          return [];
        }
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  }

  async getAuthFunctionTree(userId) {
    return httpHandler
      .get(
        '/function_list/get_auth_function_tree/?user_id=' + userId.toString()
      )
      .then((response) => {
        if (response.success) {
          return response.data;
        } else {
          window.antdNotify('錯誤', response.message, 'fail');
          return [];
        }
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  }

  convertToTreeData(data = []) {
    let itemMap = {};

    data.forEach((item) => {
      itemMap[item.id] = {
        title: item.description,
        key: item.id.toString(),
        children: [],
      };
    });

    let treeData = [];
    data.forEach((item) => {
      if (item.parent === 0) {
        treeData.push(itemMap[item.id]);
      } else if (itemMap[item.parent]) {
        itemMap[item.parent].children.push(itemMap[item.id]);
      }
    });

    return treeData;
  }

  flattenTree(tree, parentKey = null) {
    return tree.reduce((acc, node) => {
      acc.push({ key: node.key, parentKey: parentKey });
      if (node.children && node.children.length > 0) {
        acc = acc.concat(this.flattenTree(node.children, node.key));
      }
      return acc;
    }, []);
  }

  filterChildrenKeys(tree, keys) {
    const flatTree = this.flattenTree(tree);
    const parentKeys = new Set(
      flatTree
        .filter((node) => node.parentKey !== null)
        .map((node) => node.parentKey)
    );
    return keys.filter((key) => !parentKeys.has(key));
  }
}

export { FunctionListHandler };
