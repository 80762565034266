import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const ConfirmModal = () => {
  const [modal, contextHolder] = Modal.useModal();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.antdConfirm = (msg, title, okFunction) => {
      return new Promise((resolve, reject) => {
        const handleOk = async () => {
          setLoading(true);
          if (okFunction && typeof okFunction === 'function') {
            await okFunction();
            resolve(true);
          } else {
            setLoading(false);
            resolve(true);
          }
        };
        const handleCancel = () => {
          resolve(false);
        };
        modal.confirm({
          title: title ? title : '確認',
          icon: <ExclamationCircleOutlined />,
          content: msg,
          okText: '確定',
          onOk: handleOk,
          cancelText: '取消',
          onCancel: handleCancel,
          zIndex: 9999,
          centered: true,
          className: 'confirm-modal',
          confirmLoading: loading,
        });
      });
    };
  }, []);

  return <>{contextHolder}</>;
};

export default ConfirmModal;
/*
使用說明：
const onFinishCreate = async (values) => {
  const confirmed = await window.antdConfirm('你確定要進行此操作嗎？');
  if (confirmed) {
    console.log('User confirmed');
  } else {
    console.log('User not confirmed');
    return;
  }
外面的function要掛async 裡面的antd confirm要掛 await 其他照舊
*/
