import { React, useState, useEffect } from 'react';
import PageHeader from 'utility/PageHeader';
import PageBody from 'utility/PageBody';
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Table,
  Modal,
  Typography,
  Select,
  Upload,
  Image,
} from 'antd';
import {
  SearchOutlined,
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { HttpHandler } from '../../shared/httpHandler';
import PropTypes from 'prop-types';
const { Paragraph } = Typography;
const httpHandler = new HttpHandler();

const itemTypeMap = {
  0: '全部',
  1: '點心',
  2: '客休服務',
};
const subTypeMap = {
  0: '客休用品',
  1: '冷飲',
  2: '熱飲',
  3: '點心',
};
// 父組件
const CustomLounge = () => {
  const [searchForm] = Form.useForm();
  const [modalForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
    updateData(pagination);
  };

  const updateData = (pagination = tableParams.pagination) => {
    const { itemType, name } = searchForm.getFieldsValue();
    const { current, pageSize } = pagination;
    setLoading(true);
    httpHandler
      .get(
        `/custom_lounge/list?name=${name}&item_type=${itemType}&page=${current}&page_size=${pageSize}&is_all=false`
      )
      .then((response) => {
        if (response.success) {
          setTableData(response.data);
          setTableParams({
            ...tableParams,
            pagination: {
              ...pagination,
              total: response.total,
            },
          });
        } else {
          window.antdNotify('錯誤', response.data.message, 'fail');
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (id) => {
    Modal.confirm({
      maskClosable: true, // 點擊蒙層是否允許關閉
      title: '確定刪除？',
      icon: <QuestionCircleOutlined />,
      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <OkBtn loading={deleteLoading} />
        </>
      ),
      okText: '確定',
      cancelText: '取消',
      onOk: async () => {
        setDeleteLoading(true);
        return httpHandler
          .delete(`/custom_lounge/${id}`)
          .then((response) => {
            if (response.success) {
              window.antdNotify('成功', '刪除成功', 'success');
              searchCustomLoungeItem();
            } else {
              window.antdNotify('錯誤', response.data.message, 'fail');
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            setDeleteLoading(false);
          });
      },
    });
  };

  const searchCustomLoungeItem = async () => {
    setTableParams({
      ...tableParams,
      pagination: {
        current: 1,
        pageSize: 10,
      },
    });
    updateData({
      current: 1,
      pageSize: 10,
    });
  };

  useEffect(() => {
    // 預設表單值
    searchForm.setFieldsValue({
      name: '',
      itemType: '0',
    });
    // 進入畫面時執行一次搜尋
    searchCustomLoungeItem();
  }, []);
  // 搜尋組件
  const SearchBar = () => {
    return (
      <>
        <Form
          form={searchForm}
          name="searchForm"
          onFinish={searchCustomLoungeItem}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="品項名稱" name="name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item label="種類" name="itemType">
                <Select>
                  {Object.keys(itemTypeMap).map((key) => (
                    <Select.Option key={key} value={key}>
                      {itemTypeMap[key]}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={16} className="button-group">
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setIsNew(true);
                  setIsModalVisible(true);
                }}
                className="button button--register">
                新增
              </Button>
              <Button
                type="primary"
                icon={<SearchOutlined />}
                htmlType="submit"
                className="button button--search">
                搜尋
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    );
  };
  // 表格組件
  const DataTable = () => {
    const [isReadMoreModalVisible, setIsReadMoreModalVisible] = useState(false);
    const [currentText, setCurrentText] = useState('');
    const readMore = (text) => {
      const maxLength = 10; // 最大顯示的字元數
      if (text.length > maxLength) {
        return (
          <span>
            {text.substring(0, maxLength)}...
            <a
              onClick={() => {
                setCurrentText(text);
                setIsReadMoreModalVisible(true);
              }}>
              閱讀更多
            </a>
          </span>
        );
      }
      return text;
    };
    let columns = [
      {
        title: 'id',
        dataIndex: 'id',
        hidden: true,
      },
      {
        title: '品項',
        dataIndex: 'name',
      },
      {
        title: '操作',
        dataIndex: '',
        render: (text, record, index) => {
          return (
            <>
              <Button
                icon={<EditOutlined />}
                type="primary"
                className="button--edit"
                style={{ marginRight: 16 }}
                onClick={() => {
                  setIsModalVisible(true);
                  setIsNew(false);
                  modalForm.setFieldsValue({
                    id: record.id,
                    name: record.name,
                    itemType: `${record.item_type}`,
                    memo: record.memo,
                    subType: `${record.sub_type}`,
                  });
                }}>
                修改
              </Button>
              <Button
                icon={<DeleteOutlined />}
                type="primary"
                className="button--delete"
                onClick={() => {
                  handleDelete(record.id);
                }}>
                刪除
              </Button>
            </>
          );
        },
      },
      {
        title: '圖片',
        dataIndex: '',
        render: (text, record, index) => {
          if (!record.image_base64) return '未提供圖片';
          return (
            <Image
              src={record.image_base64}
              alt="Item"
              style={{
                maxWidth: '100px',
                maxHeight: '100px',
                width: 'auto',
                height: 'auto',
              }} // 設定圖片的最大寬度和高度，並按比例縮放
            />
          );
        },
      },
      {
        title: '種類',
        dataIndex: 'item_type',
        render: (text) => {
          return itemTypeMap[text];
        },
      },
      {
        title: '子分類',
        dataIndex: 'sub_type',
        render: (text) => {
          return subTypeMap[text];
        },
      },
      {
        title: '備註',
        dataIndex: 'memo',
        render: (text) => {
          if (text) {
            return readMore(text);
          }
        },
      },
      {
        title: '修改時間',
        dataIndex: 'update_time',
      },
      {
        title: '修改人員',
        dataIndex: 'update_user',
      },
      {
        title: '建立時間',
        dataIndex: 'create_time',
      },
      {
        title: '建立人員',
        dataIndex: 'create_user',
      },
    ].filter((item) => !item.hidden);

    return (
      <>
        <Table
          columns={columns}
          rowKey={(row) => row.id}
          dataSource={tableData}
          pagination={tableParams.pagination}
          loading={loading}
          bordered="true"
          scroll={{ x: 'max-content' }}
          onChange={handleTableChange}
        />
        <Modal
          title="完整內容"
          open={isReadMoreModalVisible}
          onOk={() => setIsReadMoreModalVisible(false)}
          onCancel={() => setIsReadMoreModalVisible(false)}>
          <Typography>
            <Paragraph>
              <pre>{currentText}</pre>
            </Paragraph>
          </Typography>
        </Modal>
      </>
    );
  };

  const section = [
    {
      hasTitle: false,
      title: 'Search',
      children: <SearchBar />,
    },
    {
      hasTitle: false,
      title: 'Data',
      children: <DataTable />,
    },
    {
      hasTitle: false,
      title: 'Modal',
      children: (
        <CustomLoungeModal
          searchCustomLoungeItem={searchCustomLoungeItem}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          modalForm={modalForm}
          isNew={isNew}
        />
      ),
    },
  ];

  return (
    <div>
      <PageHeader functionName={'custom_lounge'}></PageHeader>
      <PageBody section={section}></PageBody>
    </div>
  );
};
// 共用的Modal
const CustomLoungeModal = (props) => {
  const {
    searchCustomLoungeItem,
    isModalVisible,
    setIsModalVisible,
    modalForm,
    isNew,
  } = props;
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const handleImageUpload = async (postData) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = function () {
        postData['image_base64'] = reader.result;
        console.log('postData: ', postData);
        resolve();
      };
      reader.onerror = reject;
      reader.readAsDataURL(fileList[0]['originFileObj']);
    });
  };
  const handleModalSubmit = async () => {
    try {
      const values = await modalForm.validateFields();
      const confirmed = await window.antdConfirm(
        `確定${isNew ? '新增' : '修改'}品項嗎？`
      );
      if (!confirmed) return;
      setLoading(true);
      // 根據isNew判斷是新增還是修改
      let requestPromise;
      const msg = isNew ? '新增成功' : '修改成功';
      let postData = {
        name: values.name,
        item_type: values.itemType,
        memo: values.memo,
        image_base64: '',
        sub_type: values.subType,
      };
      // 如果有更新圖檔，則傳送到後端
      if (fileList.length > 0) {
        await handleImageUpload(postData);
      }
      if (isNew === true) {
        requestPromise = httpHandler.post('/custom_lounge/', postData);
      } else {
        requestPromise = httpHandler.put(
          `/custom_lounge/${values.id}`,
          postData
        );
      }

      requestPromise
        .then((response) => {
          if (response.success) {
            window.antdNotify('成功', msg, 'success');
            setIsModalVisible(false);
            searchCustomLoungeItem();
            modalForm.resetFields();
            setLoading(false);
          } else {
            setLoading(false);
            window.antdNotify('錯誤', response.message, 'fail');
          }
        })
        .finally(() => {
          setFileList([]);
          setLoading(false);
        });
    } catch (error) {
      console.error('error: ', error);
    }
  };
  const handleModalCancel = () => {
    modalForm.resetFields();
    setIsModalVisible(false);
  };
  return (
    <Modal
      title={isNew ? '新增品項' : '修改品項'}
      open={isModalVisible}
      onCancel={handleModalCancel}
      footer={[
        <Button key="back" onClick={handleModalCancel}>
          取消
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handleModalSubmit}
          loading={loading}>
          確定
        </Button>,
      ]}
      okButtonProps={{ loading: loading }}>
      <ModalForm
        modalForm={modalForm}
        isNew={isNew}
        fileList={fileList}
        setFileList={setFileList}
      />
    </Modal>
  );
};
// 共用Modal專用的Form(新增、修改共用)
const ModalForm = (props) => {
  const { modalForm, isNew, fileList, setFileList } = props;
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const handleChange = ({ fileList }) => {
    setFileList(fileList);
  };
  // 上傳前檢查
  const beforeUpload = (file) => {
    // 檢查檔案類型
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      window.antdNotify('錯誤', '只能上傳圖片檔案', 'fail');
      return Upload.LIST_IGNORE;
    }
    // 檢查檔案大小
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      window.antdNotify('錯誤', '圖片大小不能超過10MB', 'fail');
      return Upload.LIST_IGNORE;
    }
    return false;
  };
  // 上傳圖片轉base64
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  // 預覽圖片
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewTitle(file.name);
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  // 取消預覽
  const handleCancel = () => setPreviewVisible(false);
  return (
    <Form form={modalForm}>
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item
        label="品項名稱"
        name="name"
        rules={[{ required: true, message: '請輸入品項名稱!' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        label="種類"
        name="itemType"
        rules={[{ required: true, message: '請選擇種類!' }]}>
        <Select>
          {Object.keys(itemTypeMap)
            .filter((key) => key !== '0')
            .map((key) => (
              <Select.Option key={key} value={key}>
                {itemTypeMap[key]}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label="子分類"
        name="subType"
        rules={[{ required: true, message: '請選擇子分類!' }]}>
        <Select>
          {Object.keys(subTypeMap).map((key) => (
            <Select.Option key={key} value={key}>
              {subTypeMap[key]}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="上傳圖片" name="file">
        <Upload
          fileList={fileList}
          onChange={handleChange}
          listType="picture"
          beforeUpload={beforeUpload}
          onPreview={handlePreview}
          maxCount={1}>
          <Button icon={<PlusOutlined />}>上傳</Button>
        </Upload>
      </Form.Item>
      {/* 預覽Modal */}
      <Modal
        open={previewVisible}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
      <Form.Item label="備註" name="memo">
        <Input.TextArea />
      </Form.Item>
    </Form>
  );
};

// 驗證props
CustomLoungeModal.propTypes = {
  searchCustomLoungeItem: PropTypes.func,
  isModalVisible: PropTypes.bool,
  setIsModalVisible: PropTypes.func,
  modalForm: PropTypes.object,
};
ModalForm.propTypes = {
  modalForm: PropTypes.object,
};
export default CustomLounge;
