import React from 'react';
import { Result, Button } from 'antd';

const Error403 = () => (
  <Result
    status="403"
    title="403"
    subTitle="對不起，您的權限不足，無法訪問此頁面。"
    // extra={
    //   <Button type="primary" href="/home">
    //     返回首頁
    //   </Button>
    // }
  />
);

export default Error403;
