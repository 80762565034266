import { React, useState } from 'react';
import { Modal, Button, Form, Input, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { LockOutlined } from '@ant-design/icons';
import * as AntdIcons from '@ant-design/icons';
import LoadingComponent from '../../../shared/LoadingComponent';
import { HttpHandler } from '../../../shared/httpHandler';

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const getAntdIcon = (icon) => {
  const AntdIcon = AntdIcons[icon];
  return AntdIcon ? <AntdIcon /> : <></>;
};

const ChangePasswordModal = () => {
  const httpHandlerT = new HttpHandler(true);
  const httpHandlerF = new HttpHandler(false);
  const [editForm] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <>
      <LoadingComponent loading={loading}></LoadingComponent>
      <Button
        icon={<LockOutlined />}
        type="primary"
        className="button--edit"
        onClick={() => setVisible(true)}>
        {'變更密碼.側邊欄.變更密碼'}
      </Button>
      <Modal
        title={'變更密碼.編輯區.變更密碼'}
        open={visible}
        onCancel={() => {
          setVisible(false);
          editForm.resetFields();
        }}
        footer={[
          <Button form={'editForm'} key="save" type="primary" htmlType="submit">
            {'變更密碼.編輯區.儲存'}
          </Button>,
          <Button
            key="back"
            onClick={() => {
              setVisible(false);
              editForm.resetFields();
            }}>
            {'變更密碼.編輯區.關閉'}
          </Button>,
        ]}>
        <Form
          id="editForm"
          layout="vertical"
          form={editForm}
          onFinish={() => {
            setLoading(true);
            const changePW = new Promise((resolve, reject) => {
              httpHandlerF
                .gql(
                  `mutation ($account: String!, $taxNumber: String!, $password: String!) { 
                login(account: $account, taxNumber: $taxNumber, password: $password) { 
                  success 
                  message 
                  data 
                  total 
                } 
              }`,
                  {
                    account: localStorage.getItem('account').split('_')[1],
                    taxNumber: localStorage.getItem('compid'),
                    password: editForm.getFieldValue('oldPassword'),
                  },
                  false
                )
                .then((response) => {
                  const result = response.data.login;
                  if (result.success) {
                    resolve();
                  } else {
                    reject(result);
                  }
                });
            });

            changePW
              .then(() => {
                httpHandlerT
                  .post(
                    '/sub_account_management/post_reset_sub_account_pawd_normal',
                    {
                      seqno: localStorage.getItem('seqno'),
                      pawd: editForm.getFieldValue('newPassword'),
                    }
                  )
                  .then((response) => {
                    if (response.success) {
                      window.antdNotify(
                        '變更密碼.通知.變更密碼',
                        '變更密碼.通知.成功',
                        'success'
                      );
                      setVisible(false);
                      editForm.resetFields();
                    } else {
                      throw new Error(response.message);
                    }
                  })
                  .catch((error) => {
                    window.antdNotify(
                      '變更密碼.通知.變更失敗',
                      `${error}`,
                      'fail'
                    );
                  });
              })
              .catch((result) => {
                window.antdNotify(
                  '變更密碼.通知.驗證失敗',
                  `${result.data.message}`,
                  'fail'
                );
              })
              .finally(() => {
                setLoading(false);
              });
          }}>
          <Divider></Divider>
          <Form.Item
            label={'變更密碼.編輯區.舊密碼'}
            name="oldPassword"
            rules={[
              {
                required: true,
                message: '變更密碼.編輯區.請輸入舊密碼',
              },
            ]}>
            <Input.Password />
          </Form.Item>
          <Form.Item
            label={'變更密碼.編輯區.新密碼'}
            name="newPassword"
            rules={[
              {
                required: true,
                message: '變更密碼.編輯區.請輸入新密碼',
              },
            ]}>
            <Input.Password />
          </Form.Item>
          <Form.Item
            label={'變更密碼.編輯區.確認新密碼'}
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: '變更密碼.編輯區.請再次輸入新密碼',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    // 密碼匹配或確認密碼為空
                    return Promise.resolve();
                  }
                  return Promise.reject('變更密碼.編輯區.與新密碼不符');
                },
              }),
            ]}>
            <Input.Password />
          </Form.Item>
          <Divider></Divider>
        </Form>
      </Modal>
    </>
  );
};

// 遍歷無限階層的 Menu 功能清單，並檢查每一個功能是否有被授權，然後轉換成 antd 的 Menu Item。
function traverseMenuItem(menuItem) {
  if (Array.isArray(menuItem)) {
    return menuItem.flatMap(function (obj) {
      // 建立一個新的物件，用於存放遍歷後的結果
      let newObj = Object.assign({}, obj);

      // 如果物件有子陣列 children，則遞迴呼叫 traverseArray 函式遍歷子陣列
      if (obj.children && obj.children.length > 0) {
        newObj.children = traverseMenuItem(obj.children);
      }

      if (newObj.url) {
        return getItem(
          <Link to={newObj.url}>
            <span>{newObj.description}</span>
          </Link>,
          newObj.id,
          getAntdIcon(newObj.antd_icon),
          newObj.children.length > 0 ? newObj.children : null,
          null
        );
      } else if (newObj.children && newObj.children.length === 0) {
        // 當children不存在或長度為0時，不加入新物件到結果中
        return []; // 返回空陣列，flatMap 則會忽略該物件
      } else {
        return getItem(
          <span>{newObj.description}</span>,
          newObj.id,
          getAntdIcon(newObj.antd_icon),
          newObj.children,
          null
        );
      }
    });
  } else {
    return [];
  }
}

// 取得整理後的 Menu 功能清單
export async function getMenuItems() {
  const userFunctionTreeStr = localStorage.getItem('userFunctionTree') || '[]';
  const userFunctionTree = JSON.parse(userFunctionTreeStr);
  const fullItems = traverseMenuItem(userFunctionTree);

  return fullItems.length > 0 && fullItems[0].children.length > 0
    ? fullItems[0].children
    : [];
}

export function getFunctionPath(functionName) {
  const userFunctionTreeStr = localStorage.getItem('userFunctionTree') || '[]';
  const userFunctionTree = JSON.parse(userFunctionTreeStr);

  let path = [];

  function search(node, name) {
    if (node.name === name) {
      path.push(node);
      return true;
    }
    if (node.children && node.children.length > 0) {
      for (let child of node.children) {
        if (search(child, name)) {
          path.push(node);
          return true;
        }
      }
    }
    return false;
  }

  for (let node of userFunctionTree) {
    if (search(node, functionName)) {
      return path.reverse();
    }
  }

  return [];
}
