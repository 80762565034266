import React from 'react';
import { Route, Routes } from 'react-router-dom';

import DrinkMenu from 'pages/mobile/cx/DrinkMenu';
import LoungeService from 'pages/mobile/cx/LoungeService';
import FeaturedProduct from 'pages/mobile/cx/FeaturedProduct';
import HotaiPoints from 'pages/mobile/cx/HotaiPoints';
import MaintenanceProgress from 'pages/mobile/cx/MaintenanceProgress';

const CXRouter = () => {
  return (
    <Routes>
      <Route path="/DrinkMenu" element={<DrinkMenu />}></Route>
      <Route path="/LoungeService/*" element={<LoungeService />}></Route>
      <Route path="/FeaturedProduct/*" element={<FeaturedProduct />}></Route>
      <Route path="/HotaiPoints/*" element={<HotaiPoints />}></Route>
      <Route path="/MaintenanceProgress/*" element={<MaintenanceProgress />}></Route>
    </Routes>
  );
};

export default CXRouter;

// CXRouter.js - “CX”常用於代表“Customer Experience”，業界廣泛使用的縮寫。