import { Layout, Space, Menu, Flex, Button, Dropdown } from 'antd';
import { useState, React, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import {
  LogoutOutlined,
  DownOutlined,
  UserOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { getMenuItems } from './home/menuItem';
import { HttpHandler } from '../../shared/httpHandler';
import hotaiLogo from './../../images/hotaiLogo.png';

const Home = () => {
  const httpHandler = new HttpHandler();
  const [menuLoading, setMenuLoading] = useState(true);
  const [menuItems, setMenuItems] = useState([]);
  const items = [
    {
      label: 'Setting',
      key: 'Setting',
      disabled: true,
    },
    {
      type: 'divider',
    },
    {
      label: <a onClick={() => window.logout()}>登出</a>,
      key: 'logout',
      icon: <LogoutOutlined />,
    },
  ];

  const checkToken = async () => {
    // 檢查 token 是否過期，過期直接登出！
    const token = localStorage.getItem('irentAccessToken');
    let headers = await httpHandler.getHeader('application/json');
    if (!token) {
      window.logout('', '', '', false);
    } else if (headers === null) {
      window.logout('登入逾時', '請重新登入', 'fail');
    }
  };

  const updateMenu = async () => {
    setMenuLoading(true);
    const menuItems = await getMenuItems();
    setMenuItems(menuItems);
    setMenuLoading(false);
  };

  useEffect(() => {
    checkToken();
    updateMenu();
  }, []);

  const { Header, Content, Footer, Sider } = Layout;
  const [siderCollapse, setSiderCollapse] = useState(false);

  return (
    <Layout className="home" theme="light">
      <Sider
        collapsible="true"
        collapsed={siderCollapse}
        onCollapse={() => {
          setSiderCollapse(!siderCollapse);
        }}
        theme="dark">
        {menuLoading ? (
          <SyncOutlined className="menu-loading-icon" spin />
        ) : (
          <Menu
            theme="dark"
            mode="inline"
            // openKeys={[2]}
            items={menuItems}></Menu>
        )}
      </Sider>
      <Layout>
        <Header className="home-header">
          <div className="companyname">
            <Space>
              <img src={hotaiLogo} alt="Logo" className="h-8" />
              和泰智能客休管理平台
            </Space>
          </div>
          <div>
            <Space>
              <Dropdown menu={{ items }} placement="bottomRight">
                <Button>
                  <UserOutlined />
                  {localStorage.getItem('userName')}
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Space>
          </div>
        </Header>
        <Content className="home-content">
          {/* 
          使用 <Outlet /> 渲染所有匹配的 child route component, 
          並同時確保了 child route 依賴於 parent route.
          */}
          <Outlet />
        </Content>
        <Footer>
          <Flex justify="space-between">
            <span></span>
            <span className="login-info">
              <div>
                <span>版本:&nbsp;{process.env.REACT_APP_VERSION}</span>
                &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                <span>登入時間:&nbsp;{localStorage.getItem('loginTime')}</span>
              </div>
            </span>
          </Flex>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Home;
