import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CustomLounge from 'pages/customLounge/CustomLounge';

const CustomLoungeRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<CustomLounge />} />
    </Routes>
  );
};

export default CustomLoungeRouter;
