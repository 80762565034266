import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ChangePassword from 'pages/changePassword/ChangePassword';

const ChangePasswordRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<ChangePassword />} />
    </Routes>
  );
};

export default ChangePasswordRouter;
