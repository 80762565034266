import React from 'react';
import { Result, Button } from 'antd';

const Error404 = () => (
  <Result
    status="404"
    title="404"
    subTitle="對不起，您訪問的頁面不存在。"
    // extra={
    //   <Button type="primary" href="/home">
    //     返回首頁
    //   </Button>
    // }
  />
);

export default Error404;
