import { useState, React } from 'react';
import PageHeader from '../../utility/PageHeader';
import PageBody from 'utility/PageBody';
import { Form, Input, Button, Modal } from 'antd';
import { LockOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { HttpHandler } from '../../shared/httpHandler';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { CryptoHandler } from '../../shared/cryptoHandler';

const ChangePassword = () => {
  dayjs.extend(utc);
  const httpHandler = new HttpHandler(true);
  const cryptoHandler = new CryptoHandler();
  const [loading, setLoading] = useState(false);
  const ChangePasswordForm = () => {
    //func
    const handlePwdChange = (values) => {
      Modal.confirm({
        maskClosable: true, // 點擊蒙層是否允許關閉
        title: '確認變更密碼嗎？',
        icon: <QuestionCircleOutlined />,
        footer: (_, { OkBtn, CancelBtn }) => (
          <>
            <CancelBtn />
            <OkBtn loading={setLoading} />
          </>
        ),
        okText: '確定',
        cancelText: '取消',
        onOk: async () => {
          setLoading(true);
          const postData = {
            pwd: cryptoHandler.encrypt(values.pwd),
            new_pwd: cryptoHandler.encrypt(values.new_pwd),
          };
          return httpHandler
            .put(`/auth/change_password`, postData)
            .then((response) => {
              if (response.success) {
                window.antdNotify(
                  '成功',
                  '變更密碼成功，請重新登入',
                  'success'
                );
                setLoading(false);
                window.logout('已登出', '', 'success', false);
              } else {
                console.log(response);
                window.antdNotify('失敗', response.message, 'fail');
                setLoading(false);
              }
            })
            .catch((error) => {
              setLoading(false);
              console.log(error);
            });
        },
      });
    };

    return (
      <Form onFinish={handlePwdChange}>
        <Form.Item
          name="pwd"
          rules={[
            {
              required: true,
              message: '請輸入密碼',
            },
          ]}>
          <Input.Password
            prefix={
              <>
                <LockOutlined />
                <span>原密碼&nbsp;&nbsp;</span>
              </>
            }
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="new_pwd"
          rules={[
            {
              required: true,
              message: '請輸入密碼',
            },
          ]}>
          <Input.Password
            prefix={
              <>
                <LockOutlined />
                <span>新密碼&nbsp;&nbsp;</span>
              </>
            }
            size="large"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" size="large" block>
            變更
          </Button>
        </Form.Item>
      </Form>
    );
  };
  const section = [
    {
      hasTitle: false,
      title: 'Form ',
      children: <ChangePasswordForm />,
    },
  ];
  //   -------------------------------------------
  return (
    <div>
      <PageHeader functionName={'changePassword'}></PageHeader>
      <PageBody section={section}></PageBody>
    </div>
  );
};

export default ChangePassword;
