import React from 'react';
import _axios from 'axios';
import moment from 'moment-timezone';
import { HttpHandler } from 'shared/httpHandler';
import { CryptoHandler } from 'shared/cryptoHandler';

const cryptoHandler = new CryptoHandler();

class WebviewHttpHandler extends HttpHandler {
  constructor() {
    super(true, process.env.REACT_APP_BASE_URL || 'http://localhost:8000');
  }
  async loginUseWebview() {
    await this.axios()
      .post('/webview/auth/login', {
        account: cryptoHandler.encrypt(
          process.env.REACT_APP_MGMT_WEBVIEW_ACCOUNT
        ),
        password: cryptoHandler.encrypt(
          process.env.REACT_APP_MGMT_WEBVIEW_PASSWORD
        ),
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.success) {
            // 將token存到localStorage
            WebviewAuthValid.storeAuth(
              response.data.data.token,
              response.data.data.re_token
            );
          } else {
            this.navigateTo('error401');
          }
        } else {
          this.navigateTo('error500');
        }
      })
      .catch((error) => {
        this.navigateTo('error500');
      });
  }
  async refreshTokenUseWebview(headers) {
    await this.axios()
      .post('/webview/auth/refresh_token', {}, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return null;
        }
      })
      .then((result) => {
        // 更新 AccessToken
        localStorage.setItem('WebviewAccessToken', result.data.token);
        // 延長token duration
        WebviewAuthValid.storeAccessAuth(result.data.token);
        headers.authorization = 'Bearer ' + result.data.token;
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          return { success: false, message: '連線錯誤.' };
        }
        if (error.response.status === 401) {
          return null;
        } else if (error.response.status === 422) {
          let detail = undefined;
          if ('detail' in error.response.data) {
            detail = error.response.data.detail;
          }
          return {
            success: false,
            message: '後端API資料驗證失敗, 請確認.',
            detail: detail,
          };
        } else {
          return null;
        }
      });
  }
  // post method
  async post(url, data, contentType = 'application/json') {
    let headers = await this.getHeader(contentType);
    // token 過期
    if (headers === null) {
      window.logout('登入逾時', '請重新登入', 'fail');
    }

    return this.axios()
      .post(url, data, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return { success: false, message: 'http handler error' };
        }
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          return { success: false, message: '連線錯誤.' };
        }
        if (error.response.status === 401) {
          this.navigateTo('error401');
        } else if (error.response.status === 422) {
          return { success: false, message: error.response.data.detail[0].msg };
        } else {
          return { success: false, message: 'http handler error' };
        }
      })
      .finally(() => {});
  }

  // put method
  async put(url, data, contentType = 'application/json') {
    let headers = await this.getHeader(contentType);
    // token 過期
    if (headers === null) {
      window.logout('登入逾時', '請重新登入', 'fail');
    }

    return this.axios()
      .put(url, data, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return { success: false, message: 'http handler error' };
        }
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          return { success: false, message: '連線錯誤.' };
        }
        if (error.response.status === 401) {
          this.navigateTo('error401');
        } else if (error.response.status === 422) {
          return { success: false, message: error.response.data.detail[0].msg };
        } else {
          return { success: false, message: 'http handler error' };
        }
      })
      .finally(() => {});
  }

  // get method
  async get(url) {
    let headers = await this.getHeader('application/json');
    // token 過期
    if (headers === null) {
      window.logout('登入逾時', '請重新登入', 'fail');
    }

    return this.axios()
      .get(url, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          // 轉換時間為台灣時區
          if (Array.isArray(response.data.data)) {
            response.data.data.forEach(async (item) => {
              // 資料要呈現在DataTalbe時, 轉換為台灣時區
              if (item.create_time) {
                item.create_time = await this.UTCToTaiwanZone(item.create_time);
              }
              if (item.update_time) {
                item.update_time = await this.UTCToTaiwanZone(item.update_time);
              }
              if (item.cancel_time) {
                item.cancel_time = await this.UTCToTaiwanZone(item.cancel_time);
              }
            });
          }
          return response.data;
        } else {
          return { success: false, message: 'http handler error' };
        }
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          return { success: false, message: '連線錯誤.' };
        }
        if (error.response.status === 401) {
          this.navigateTo('error401');
        } else if (error.response.status === 422) {
          return { success: false, message: '後端API資料驗證失敗, 請確認.' };
        } else {
          return { success: false, message: 'http handler error' };
        }
      });
  }

  // delete method
  async delete(url) {
    let headers = await this.getHeader('application/json');
    // token 過期
    if (headers === null) {
      window.logout('登入逾時', '請重新登入', 'fail');
    }

    return this.axios()
      .delete(url, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return { success: false, message: 'http handler error' };
        }
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          return { success: false, message: '連線錯誤.' };
        }
        if (error.response.status === 401) {
          this.navigateTo('error401');
        } else if (error.response.status === 422) {
          return { success: false, message: '後端API資料驗證失敗, 請確認.' };
        } else {
          return { success: false, message: 'http handler error' };
        }
      });
  }

  async getHeader(content_type = 'application/json') {
    let headers;

    if (content_type !== '') {
      headers = { 'Content-Type': content_type };
    } else {
      headers = {};
    }
    // 帶上當前URL
    headers['X-Referer'] = window.location.href;

    const accessToken = localStorage.getItem('WebviewAccessToken');
    const refreshToken = localStorage.getItem('WebviewRefreshToken');
    const accessExpTime = localStorage.getItem('WebviewAccessTokenExpireTime');
    const refreshExpTime = localStorage.getItem(
      'WebviewRefreshTokenExpireTime'
    );

    const now = moment();
    const accessExpMoment = moment(accessExpTime, 'YYYY/MM/DD HH:mm:ss');
    const refreshExpMoment = moment(refreshExpTime, 'YYYY/MM/DD HH:mm:ss');

    if (this.checkToken) {
      // 尚未登入
      if (accessExpTime === null) {
        await this.loginUseWebview();
        headers.authorization =
          'Bearer ' + localStorage.getItem('WebviewAccessToken');
        return headers;

        // AccessToken 和 RefreshToken 都過期 -> 重新登入
      } else if (
        now.isAfter(accessExpMoment) &&
        now.isAfter(refreshExpMoment)
      ) {
        // 將token存到localStorage
        await this.loginUseWebview();
        headers.authorization =
          'Bearer ' + localStorage.getItem('WebviewAccessToken');
        return headers;

        // 只有AccessToken過期 -> 用RefreshToken取得新的AccessToken
      } else if (now.isAfter(accessExpMoment)) {
        headers.authorization = 'Bearer ' + refreshToken;
        // 將token存到localStorage
        await this.refreshTokenUseWebview(headers);
        headers.authorization =
          'Bearer ' + localStorage.getItem('WebviewAccessToken');
        return headers;
      } else {
        // token沒過期
        headers.authorization = 'Bearer ' + accessToken;
        return headers;
      }
    } else {
      return headers;
    }
  }

  // UTC轉換為台灣時區
  async UTCToTaiwanZone(time) {
    // 時間結尾需要加上'Z'才能被視為UTC時間
    time = new Date(time + 'Z').toLocaleString('zh-TW', {
      timeZone: 'Asia/Taipei',
    });
    return time;
  }
}

// auth helper
class WebviewAuthValid {
  // save login info
  static storeAuth(access, refresh) {
    localStorage.setItem('WebviewAccessToken', access);
    localStorage.setItem('WebviewRefreshToken', refresh);

    const payLoad = WebviewAuthValid.parseJwt(access);

    // 使用者資訊
    localStorage.setItem(
      'approved_webview_list',
      payLoad.approved_webview_list
    );

    // 登入時間
    localStorage.setItem(
      'loginTime',
      moment
        .utc(payLoad.token_time)
        .tz(moment.tz.guess())
        .format('YYYY/MM/DD HH:mm:ss')
    );

    // AccessToken 過期分鐘數
    localStorage.setItem(
      'WebviewAccessTokenExpireMinutes',
      payLoad.token_expire_minutes
    );

    // RefreshToken 過期分鐘數
    localStorage.setItem(
      'WebviewRefreshTokenExpireMinutes',
      payLoad.re_token_expire_minutes
    );

    // AccessToken 過期時間
    localStorage.setItem(
      'WebviewAccessTokenExpireTime',
      moment(new Date())
        .add(parseInt(payLoad.token_expire_minutes) - 5, 'minutes')
        .format('YYYY/MM/DD HH:mm:ss')
    );

    // RefreshToken 過期時間
    localStorage.setItem(
      'WebviewRefreshTokenExpireTime',
      moment(new Date())
        .add(parseInt(payLoad.re_token_expire_minutes) - 5, 'minutes')
        .format('YYYY/MM/DD HH:mm:ss')
    );
  }

  static storeAccessAuth(access) {
    localStorage.setItem('WebviewAccessToken', access);
    const payLoad = WebviewAuthValid.parseJwt(access);

    // 使用者資訊
    localStorage.setItem(
      'approved_webview_list',
      payLoad.approved_webview_list
    );

    // AccessToken 過期分鐘數
    localStorage.setItem(
      'WebviewAccessTokenExpireMinutes',
      payLoad.token_expire_minutes
    );

    // AccessToken 過期時間
    localStorage.setItem(
      'WebviewAccessTokenExpireTime',
      moment(new Date())
        .add(parseInt(payLoad.token_expire_minutes) - 5, 'minutes')
        .format('YYYY/MM/DD HH:mm:ss')
    );
  }

  static clearAuth() {
    localStorage.removeItem('approved_webview_list');
    localStorage.removeItem('loginTime');

    localStorage.removeItem('WebviewAccessToken');
    localStorage.removeItem('WebviewRefreshToken');

    localStorage.removeItem('WebviewAccessTokenExpireMinutes');
    localStorage.removeItem('WebviewRefreshTokenExpireMinutes');

    localStorage.removeItem('WebviewAccessTokenExpireTime');
    localStorage.removeItem('WebviewRefreshTokenExpireTime');
  }

  static parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
}

export { WebviewHttpHandler, WebviewAuthValid };
