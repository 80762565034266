import React from 'react';
import _axios from 'axios';
import moment from 'moment-timezone';

class HttpHandler {
  constructor(
    checkToken = true,
    baseURL = process.env.REACT_APP_BASE_URL + '/dashboard' ||
      'http://localhost:8000/dashboard'
  ) {
    this.checkToken = checkToken;
    this.baseURL = baseURL;
  }
  axios = (baseURL) => {
    const instance = _axios.create({
      baseURL: this.baseURL,
      timeout: 5000000,
    });
    return instance;
  };

  navigateTo(url) {
    window.navigate(url);
  }

  // post method
  async post(url, data, contentType = 'application/json') {
    let headers = await this.getHeader(contentType);
    // token 過期
    if (headers === null) {
      window.logout('登入逾時', '請重新登入', 'fail');
    }

    return this.axios()
      .post(url, data, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return { success: false, message: 'http handler error' };
        }
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          return { success: false, message: '連線錯誤.' };
        }
        if (error.response.status === 401) {
          this.navigateTo('error401');
        } else if (error.response.status === 422) {
          return { success: false, message: error.response.data.detail[0].msg };
        } else {
          return { success: false, message: 'http handler error' };
        }
      })
      .finally(() => {});
  }

  // put method
  async put(url, data, contentType = 'application/json') {
    let headers = await this.getHeader(contentType);
    // token 過期
    if (headers === null) {
      window.logout('登入逾時', '請重新登入', 'fail');
    }

    return this.axios()
      .put(url, data, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return { success: false, message: 'http handler error' };
        }
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          return { success: false, message: '連線錯誤.' };
        }
        if (error.response.status === 401) {
          this.navigateTo('error401');
        } else if (error.response.status === 422) {
          return { success: false, message: error.response.data.detail[0].msg };
        } else {
          return { success: false, message: 'http handler error' };
        }
      })
      .finally(() => {});
  }

  // get method
  async get(url) {
    let headers = await this.getHeader('application/json');
    // token 過期
    if (headers === null) {
      window.logout('登入逾時', '請重新登入', 'fail');
    }

    return this.axios()
      .get(url, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          // 轉換時間為台灣時區
          if (Array.isArray(response.data.data)) {
            response.data.data.forEach(async (item) => {
              // 資料要呈現在DataTalbe時, 轉換為台灣時區
              if (item.create_time) {
                item.create_time = await this.UTCToTaiwanZone(item.create_time);
              }
              if (item.update_time) {
                item.update_time = await this.UTCToTaiwanZone(item.update_time);
              }
              if (item.cancel_time) {
                item.cancel_time = await this.UTCToTaiwanZone(item.cancel_time);
              }
            });
          }
          return response.data;
        } else {
          return { success: false, message: 'http handler error' };
        }
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          return { success: false, message: '連線錯誤.' };
        }
        if (error.response.status === 401) {
          this.navigateTo('error401');
        } else if (error.response.status === 422) {
          return { success: false, message: '後端API資料驗證失敗, 請確認.' };
        } else {
          return { success: false, message: 'http handler error' };
        }
      });
  }

  // delete method
  async delete(url) {
    let headers = await this.getHeader('application/json');
    // token 過期
    if (headers === null) {
      window.logout('登入逾時', '請重新登入', 'fail');
    }

    return this.axios()
      .delete(url, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return { success: false, message: 'http handler error' };
        }
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          return { success: false, message: '連線錯誤.' };
        }
        if (error.response.status === 401) {
          this.navigateTo('error401');
        } else if (error.response.status === 422) {
          return { success: false, message: '後端API資料驗證失敗, 請確認.' };
        } else {
          return { success: false, message: 'http handler error' };
        }
      });
  }

  async getHeader(content_type = 'application/json') {
    let headers;

    if (content_type !== '') {
      headers = { 'Content-Type': content_type };
    } else {
      headers = {};
    }

    const accessToken = localStorage.getItem('irentAccessToken');
    const refreshToken = localStorage.getItem('irentRefreshToken');
    const accessExpTime = localStorage.getItem('irentAccessTokenExpireTime');
    const refreshExpTime = localStorage.getItem('irentRefreshTokenExpireTime');

    const now = moment().utc();
    const accessExpMoment = moment(accessExpTime, 'YYYY/MM/DD HH:mm:ss').utc();
    const refreshExpMoment = moment(
      refreshExpTime,
      'YYYY/MM/DD HH:mm:ss'
    ).utc();

    if (this.checkToken) {
      if (now.isAfter(accessExpMoment) && now.isAfter(refreshExpMoment)) {
        // 全部逾時
        return null;
      } else if (now.isAfter(accessExpMoment)) {
        // refresh token
        headers.authorization = 'Bearer ' + refreshToken;
        return this.axios()
          .post('/auth/refresh_token', {}, { headers: headers })
          .then((response) => {
            if (response.status === 200) {
              return response.data;
            } else {
              return null;
            }
          })
          .then((result) => {
            // 更新 AccessToken
            localStorage.setItem('irentAccessToken', result.data.token);
            // 延長token duration
            const tokenDuration = parseInt(
              localStorage.getItem('irentAccessTokenExpireMinutes')
            );
            localStorage.setItem(
              'irentAccessTokenExpireTime',
              moment(new Date())
                .add(tokenDuration - 5, 'minutes')
                .format('YYYY/MM/DD HH:mm:ss')
            );
            headers.authorization = 'Bearer ' + result.data.token;
            return headers;
          })
          .catch((error) => {
            if (error.message === 'Network Error') {
              return { success: false, message: '連線錯誤.' };
            }
            if (error.response.status === 401) {
              return null;
            } else if (error.response.status === 422) {
              return {
                success: false,
                message: '後端API資料驗證失敗, 請確認.',
              };
            } else {
              return null;
            }
          });
      } else {
        // token沒過期
        headers.authorization = 'Bearer ' + accessToken;
        return headers;
      }
    } else {
      return headers;
    }
  }

  // UTC轉換為台灣時區
  async UTCToTaiwanZone(time) {
    // 時間結尾需要加上'Z'才能被視為UTC時間
    time = new Date(time + 'Z').toLocaleString('zh-TW', {
      timeZone: 'Asia/Taipei',
    });
    return time;
  }
}

// auth helper
class AuthValid {
  // save login info
  static storeAuth(access, refresh) {
    localStorage.setItem('irentAccessToken', access);
    localStorage.setItem('irentRefreshToken', refresh);

    const payLoad = AuthValid.parseJwt(access);

    // 使用者資訊
    const userInfo = payLoad.user_info;
    localStorage.setItem('userId', userInfo.id);
    localStorage.setItem('userAccount', userInfo.account);
    localStorage.setItem('userName', userInfo.name);
    localStorage.setItem('userDepartment', userInfo.department);
    localStorage.setItem('userReason', userInfo.reason);
    localStorage.setItem(
      'userFunctionTree',
      JSON.stringify(userInfo.function_tree)
    );

    // 登入時間
    localStorage.setItem(
      'loginTime',
      moment
        .utc(payLoad.token_time)
        .tz(moment.tz.guess())
        .format('YYYY/MM/DD HH:mm:ss')
    );

    // AccessToken 過期分鐘數
    localStorage.setItem(
      'irentAccessTokenExpireMinutes',
      payLoad.token_expire_minutes
    );

    // RefreshToken 過期分鐘數
    localStorage.setItem(
      'irentRefreshTokenExpireMinutes',
      payLoad.re_token_expire_minutes
    );

    // AccessToken 過期時間
    localStorage.setItem(
      'irentAccessTokenExpireTime',
      moment(new Date())
        .add(parseInt(payLoad.token_expire_minutes) - 5, 'minutes')
        .format('YYYY/MM/DD HH:mm:ss')
    );

    // RefreshToken 過期時間
    localStorage.setItem(
      'irentRefreshTokenExpireTime',
      moment(new Date())
        .add(parseInt(payLoad.re_token_expire_minutes) - 5, 'minutes')
        .format('YYYY/MM/DD HH:mm:ss')
    );
  }

  static parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
}

export { HttpHandler, AuthValid };
